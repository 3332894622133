/* eslint-disable @typescript-eslint/no-unused-vars */
import { isNil } from "lodash";
import { Box } from "@mui/system";
import { DEFAULT_NUM_OF_SPOTS } from "features/experiments/constants";

const HEIGHT = 140;
const QR_HEIGHT = HEIGHT - 20;
const WIDTH = QR_HEIGHT + 123 * DEFAULT_NUM_OF_SPOTS;

const GAP = 1;

interface Props {
  activeSpots?: number[] | number;
  numOfSpots?: number;
  onClick?: (spots: number[] | number) => void;
  renderSpotTitle: (i: number) => React.ReactNode | string;
}

const calculateColumns = (spots: number) => {
  if (spots <= 8) return spots;

  return Math.max(Math.ceil(spots / 2), 6);
};

const Slide: React.FC<Props> = ({
  activeSpots,
  numOfSpots,
  onClick,
  renderSpotTitle,
}) => {
  let spotCount =
    numOfSpots && typeof numOfSpots == "string"
      ? parseInt(numOfSpots, 10)
      : numOfSpots;

  if (isNil(spotCount)) spotCount = DEFAULT_NUM_OF_SPOTS;

  const cols = calculateColumns(spotCount);
  const slide_width = QR_HEIGHT + 123 * cols;
  const rows = Math.ceil(spotCount / DEFAULT_NUM_OF_SPOTS);
  const spotHeight = 110 / rows;

  const isDisabled = isNil(onClick) || isNil(activeSpots);

  const onSpotClick = (idx: number, evt: React.MouseEvent) => {
    if (isDisabled) return;

    const isModifierKey = evt.shiftKey || evt.ctrlKey || evt.metaKey;
    let spots: number[] | number = [];

    if (Array.isArray(activeSpots)) {
      if (isModifierKey) {
        // If already selected, remove from selection. Don't remove if last.
        if (activeSpots.includes(idx) && activeSpots.length > 1) {
          spots = activeSpots.filter(s => s !== idx);
        } else {
          spots = [...activeSpots, idx];
        }
      } else {
        spots = [idx];
      }
    } else {
      spots = idx;
    }

    onClick(spots);
  };

  return (
    <Box
      display={"flex"}
      p={0}
      mt={3}
      gap={0}
      width={slide_width}
      border={1}
      borderRadius={2}>
      <Box p={GAP} height={HEIGHT}>
        <img
          src="/assets/qrsample.png"
          alt="Sample QR code graphic for illustration"
          height={QR_HEIGHT}
          width={QR_HEIGHT}
        />
      </Box>
      <Box
        p={0}
        m={0}
        display={"flex"}
        flexWrap="wrap"
        alignItems={"center"}
        gap={0}
        maxWidth={slide_width - QR_HEIGHT}
        minWidth={slide_width - QR_HEIGHT}>
        {[...Array(spotCount)].map((_, idx) => {
          const isActive = Array.isArray(activeSpots)
            ? activeSpots.includes(idx)
            : activeSpots === idx;

          return (
            <Box
              p={0}
              m={0}
              display={"flex"}
              justifyContent="center"
              alignItems="center"
              key={idx}
              width={120}
              height={HEIGHT / rows}
              sx={{
                backgroundColor: isActive
                  ? "action.selected"
                  : "background.default",
              }}>
              <Box
                display={"flex"}
                flexDirection={"column"}
                justifyContent={"center"}
                alignItems={"center"}
                key={idx}
                width={spotHeight}
                height={spotHeight}
                border={1}
                borderColor={"#fff"}
                borderRadius={"100%"}
                sx={{ cursor: isDisabled ? "auto" : "pointer" }}
                onClick={evt => onSpotClick(idx, evt)}>
                {renderSpotTitle(idx)}
              </Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default Slide;
