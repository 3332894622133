import { isEqual, isNil } from "lodash";
import {
  Step,
  Steps,
  Treatment,
} from "@pavonis/pavonis-api/src/api/experiments/experiment.model";

export const getUniqueSteps = (
  steps: Steps,
  treatmentType: keyof Treatment,
  isPrimary: boolean,
) => {
  const uniqueSteps: Step[] = [];

  // to store which spots are duplicated
  const spotTitles: number[] = [];

  for (const step of steps) {
    if (isNil(step)) continue;

    const stepPhase = step[treatmentType][+!isPrimary];

    if (!uniqueSteps.some(s => isEqual(stepPhase, s))) {
      !isNil(stepPhase) && uniqueSteps.push(stepPhase);
    }
  }

  let hasBlank = false;

  // moving isBlank:true treatment to the beginning
  for (let i = 0; i < uniqueSteps.length; i++) {
    const step = uniqueSteps[i];

    if (!step) continue;

    if (step.isBlank) {
      hasBlank = true;
      const a = uniqueSteps.splice(i, 1); // removes the item

      uniqueSteps.unshift(a[0]);
    }
  }

  // filling spot titles
  for (const step of steps) {
    if (isNil(step)) continue;

    const index = uniqueSteps
      .slice(+hasBlank)
      .findIndex(
        s =>
          JSON.stringify(s) ===
          JSON.stringify(step[treatmentType][+!isPrimary]),
      );

    spotTitles.push(index + 1);
  }

  return { uniqueSteps, spotTitles };
};
