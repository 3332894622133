import { z, ZodTypeAny } from 'zod';

export const numericString = (schema: ZodTypeAny) =>
  z.preprocess((a) => {
    if (typeof a === 'number') {
      return a;
    }

    if (typeof a === 'string') {
      return !a.length ? undefined : parseInt(a as string, 10);
    }

    return undefined;
  }, schema);
