import { useLayoutEffect, useRef, useState } from "react";
import { Box, BoxProps, Typography } from "@mui/material";

interface Props extends BoxProps {
  title?: string;
  children: React.ReactNode;
}
const BorderedBox: React.FC<Props> = ({ title, children, ...props }) => {
  const [titleHeight, setTitleHeight] = useState(0);
  const titleRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (!titleRef.current) return;

    setTitleHeight(titleRef.current.clientHeight);
  }, []);

  return (
    <Box
      px={3}
      py={2}
      mt={5}
      position="relative"
      width={"100%"}
      border={1}
      borderRadius={2}
      display="flex"
      flexDirection={"column"}
      gap={3}
      {...props}>
      {!!title?.length && (
        <Box
          position="absolute"
          top={`${-titleHeight / 2}px`}
          left={16}
          px={1}
          bgcolor="#121212"
          ref={titleRef}>
          <Typography>{title}</Typography>
        </Box>
      )}
      {children}
    </Box>
  );
};

export default BorderedBox;
