/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { isEmpty, isEqual, isNil } from "lodash";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
//import { useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import HelpIcon from "@mui/icons-material/Help";
import { LoadingButton } from "@mui/lab";
import { Button } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Experiment, {
  Step,
  Steps,
  Treatment,
} from "@pavonis/pavonis-api/src/api/experiments/experiment.model";
import SlideImage, {
  ExperimentStatus,
  Rectangle,
  Color,
} from "@pavonis/pavonis-api/src/api/experiments/slides/images/image_model";
import Loader from "@pavonis/pavonis-hub/src/components/Loader";
import { WatchFields } from "features/experiments/constants";
//import {
//  createImage,
//  deleteImage,
//  updateImage,
//} from "features/experiments/slideImagesSlice";
//import { BASE_URL } from "services/api";
import {
  useArchiveSlideImageMutation,
  useCreateSlideImageMutation,
  useUpdateExperimentMutation,
  useUpdateSlideImageRectanglesMutation,
} from "services/experiments";
import { toFormData } from "utils/toFormData";
import Slide from "../Slide";
import StepWrapper from "./StepWrapper";
interface Props {
  experiment: Experiment;
}

const AdHocExperiment: FC<Props> = ({ experiment }) => {
  const { register, watch, control } = useForm({
    defaultValues: {
      [WatchFields.DESCRIPTION]: experiment?.description ?? "",
      [WatchFields.SPOTS_COUNT]: 8,
    },
  });
  //  const dispatch = useDispatch();

  const slideId = experiment?.slides?.[0]?.id ?? "";
  const [updateExperiment, { isLoading }] = useUpdateExperimentMutation();
  const [createSlideImage] = useCreateSlideImageMutation();
  const [updateSlideImage] = useUpdateSlideImageRectanglesMutation();
  const [archiveSlideImage] = useArchiveSlideImageMutation();

  const [activeSpots, setActiveSpots] = useState([0]);
  const [isImageLoading, setIsImageLoading] = useState(false);
  const [selectedStep, setSelectedStep] = useState(0);
  const [slideImages, setSlideImages] = useState(Array<SlideImage | undefined>);

  const [description, spotCount] = watch([
    WatchFields.DESCRIPTION,
    WatchFields.SPOTS_COUNT,
  ]);

  // Each one of these steps is an ad-hoc step. We use the preparation
  // field to store the status of each spot
  const steps = useMemo(() => experiment.protocol?.steps, [experiment]);

  useEffect(() => {
    console.log("Experiment updated useeffect!", Date.now());
    if (isNil(experiment) || isNil(steps)) {
      setSlideImages([]);

      return;
    }
    const i: Array<SlideImage | undefined> = Array.from(
      { length: steps.length },
      () => undefined,
    );

    experiment.slides?.[0].images?.forEach(im => {
      if (im.step_index) {
        i[im.step_index - 1] = im;
      }
    });

    console.log("Setting images to ", i);
    setSlideImages(i);
  }, [experiment, steps]);

  const colors = useMemo(() => {
    if (isNil(steps)) return [];
    const c: Array<Color[] | undefined> = Array.from(
      { length: steps.length },
      () => undefined,
    );

    experiment.slides?.[0].images?.forEach(i => {
      if (i.step_index && i.color_values) {
        const clrs = i.color_values;

        c[i.step_index - 1] = clrs;
      }
    });

    return c;
  }, [steps, experiment]);

  const spotApplications = useMemo(() => {
    const arr: Array<Step[]> = [];

    steps?.forEach(step => {
      // We should have the same number of prep items as slide spots
      const spotTreatments: Array<Step> = [];

      console.log("Processing step", step);

      step?.preparation?.forEach(s => {
        if (s) spotTreatments.push(s);
      });
      while (spotTreatments.length < spotCount) {
        const t: Step = { isBlank: true };

        spotTreatments.push(t);
      }
      arr.push(spotTreatments);
    });

    return arr;
  }, [spotCount, steps]);

  const renderSpotTitle = useCallback(
    (index: number) => {
      return (
        <>
          {spotApplications.map((treatments, idx) => {
            const thisTreatment = treatments[index];

            return (
              <Typography
                key={idx}
                variant="caption"
                sx={{ fontSize: "0.65rem", textAlign: "center" }}>
                {`${idx + 1}. ${thisTreatment.isBlank ? "B" : thisTreatment.accession
                  }\n`}
              </Typography>
            );
          })}
        </>
      );
    },
    [spotApplications, selectedStep],
  );

  const onSpotClick = (spots: number[] | number) => {
    if (!Array.isArray(spots)) return;

    setActiveSpots(spots);
  };

  const handleSlideImageCreate = useCallback(
    async (newPhoto: string, stepIndex: number) => {
      if (!experiment.id) return;

      setIsImageLoading(true);

      const formData = await toFormData(newPhoto);

      const { value } = await createSlideImage({
        id: experiment.id!,
        slideId,
        protocolStage: ExperimentStatus.AD_HOC,
        file: formData,
        stepIndex,
      }).unwrap();

      if (!value || !value.image_location) return;

      //      const slideImage = {
      //        url: `${BASE_URL}${value.image_location}`,
      //        rectangles: value.color_rectangles ?? [],
      //        id: value.id,
      //        stepIndex,
      //      };

      //      dispatch(
      //        createImage({
      //          experimentId: experiment.id,
      //          slideId,
      //          stage: ExperimentStatus.AD_HOC,
      //          image: slideImage,
      //          stepIndex,
      //        }),
      //      );
      setIsImageLoading(false);
    },
    [createSlideImage, experiment.id, slideId],
  );

  const handleSlideImageUpdate = useCallback(
    async (updatedRectangles: Rectangle[], stepIndex: number) => {
      if (!experiment.id) return;
      const imageId = slideImages[stepIndex - 1]?.id;

      if (!imageId) return;
      if (
        isEqual(slideImages[stepIndex - 1]?.color_rectangles, updatedRectangles)
      )
        return;

      setIsImageLoading(true);

      await updateSlideImage({
        id: experiment.id,
        slideId,
        imageId,
        rectangles: updatedRectangles,
      }).unwrap();

      //      dispatch(
      //        updateImage({
      //          experimentId: experiment.id,
      //          slideId,
      //          stage: ExperimentStatus.AD_HOC,
      //          stepIndex,
      //          updates: {
      //            rectangles: updatedRectangles,
      //          },
      //        }),
      //      );
      setIsImageLoading(false);
    },
    [experiment.id, slideImages, updateSlideImage, slideId],
  );

  const handleSlideImageDelete = useCallback(
    async (stepIndex: number) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const im = slideImages;

      if (!experiment.id) return;
      setIsImageLoading(true);
      const image = slideImages[stepIndex - 1];

      if (image?.id) {
        await archiveSlideImage({
          id: experiment.id,
          slideId: slideId,
          imageid: image.id,
        });
        // dispatch(
        //   deleteImage({
        //     experimentId: experiment.id,
        //     slideId,
        //     stepIndex,
        //     stage: ExperimentStatus.AD_HOC,
        //   }),
        // );
      }
      setIsImageLoading(false);
    },
    [experiment.id, slideImages, slideId, archiveSlideImage],
  );

  const addStep = useCallback(async () => {
    if (!experiment.id) return;
    const treatment: Treatment = {
      preparation: Array.from(
        { length: experiment.slide_circle_count ?? 8 },
        () => {
          const t: Step = { isBlank: true };

          return t;
        },
      ),
      analytes: [],
    };
    const newSteps: Steps = [...(steps ?? [])];

    newSteps.push(treatment);
    await updateExperiment({
      id: experiment.id,
      experiment: {
        ...experiment,
        protocol: {
          ...experiment.protocol,
          steps: newSteps,
        },
      },
    });
  }, [steps, experiment, updateExperiment]);

  const updateProtocol = useCallback(
    async (values: Step, step: number) => {
      if (!experiment.id) return;
      values.isBlank = isEmpty(values.accession?.trim());

      let newSteps: Steps = [...(steps ?? [])];

      // adding step in case it doesn't exist
      if (isNil(newSteps[step])) {
        newSteps[step] = { preparation: [{}], analytes: [{}] };
      }

      newSteps = newSteps.map((stepItem, idx) => {
        const preparation = [...(stepItem?.preparation ?? [])];

        // updating every active spot in a step
        if (idx === step) {
          activeSpots.forEach(spot => {
            preparation[spot] = values;
          });
        }

        return { preparation, analytes: [] };
      });

      if (isNil(newSteps)) return;

      await updateExperiment({
        id: experiment.id,
        experiment: {
          ...experiment,
          protocol: {
            ...experiment.protocol,
            steps: newSteps,
          },
        },
      });
    },
    [activeSpots, steps, experiment, updateExperiment],
  );

  const handleDoneExperiment = async () => {
    if (!experiment.id) return;

    await updateExperiment({
      id: experiment.id,
      experiment: {
        ...experiment,
        status: ExperimentStatus.COMPLETE,
      },
    });
  };
  //useEffect(() => {
  //if (!experiment.slides) return;

  //experiment.slides.forEach(slide => {
  //if (!slide.images) return;

  //slide.images.forEach((image, idx) => {
  // if (!slide.id || !experiment.id) return;

  //    const slideImage = {
  //      url: `${BASE_URL}${image.image_location}`,
  //      rectangles: image.color_rectangles ?? [],
  //      id: image.id,
  //    };

  //dispatch(
  //  createImage({
  //    experimentId: experiment.id,
  //    slideId: slide.id,
  //    stage: image.protocol_stage,
  //    image: slideImage,
  //    stepIndex: idx + 1,
  //  }),
  //);
  //});
  //});
  //}, [experiment.slides, experiment.id]);

  const onDescriptionBlur = async () => {
    if (!experiment?.id) return;

    await updateExperiment({
      id: experiment.id,
      experiment: {
        ...experiment,
        description,
      },
    });
  };
  //const images = getSlideImages() || [];

  return (
    <Box
      py={3}
      display="flex"
      flexDirection={"column"}
      alignItems={"center"}
      gap={3}>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems="center"
        width={"100%"}
        mt={3}>
        <Box display={"flex"} gap={2} alignItems="center">
          <Typography variant="h5" flexShrink={0} mb={0}>
            Ad-Hoc Experiment
          </Typography>
          <Tooltip title="One flow instead of a separate “preparation, conduct” steps">
            <HelpIcon />
          </Tooltip>
        </Box>
        {/*
        <LoadingButton variant="contained" size="medium" type="submit">
          Done
        </LoadingButton> */}
      </Box>
      <Grid
        mt={"-10px"}
        container
        columnSpacing={3}
        rowSpacing={2}
        alignItems="baseline">
        <Grid item xs={8} display="flex" alignItems="center" gap={3}>
          <Typography flexShrink={0}>Experiment Description</Typography>
          <TextField
            fullWidth
            size="small"
            {...register("description")}
            onBlur={onDescriptionBlur}
          />
        </Grid>
        <Grid item xs={2} display="flex" alignItems="center" gap={3}>
          <Typography flexShrink={0}>Spots</Typography>
          <TextField
            type="number"
            size="small"
            inputProps={{ min: 6, max: 16 }}
            defaultValue={8}
            {...register("spotsCount")}
          />
        </Grid>
      </Grid>
      <Slide
        numOfSpots={spotCount}
        activeSpots={activeSpots}
        onClick={onSpotClick}
        renderSpotTitle={renderSpotTitle}
      />
      <Grid container spacing={3}>
        {spotApplications.map((step, index) => (
          <StepWrapper
            key={uuidv4()}
            stepN={index + 1}
            photo={slideImages[index]}
            onPictureTake={handleSlideImageCreate}
            onImageUpdate={handleSlideImageUpdate}
            onImageDelete={handleSlideImageDelete}
            step={activeSpots.length ? step[activeSpots[0]] : undefined}
            uploadData={updateProtocol}
            isDataLoading={isLoading}
            selected={selectedStep == index}
            setSelected={() => setSelectedStep(index)}
            activeSpots={activeSpots}
            colors={colors[index]}
          />
        ))}
        <LoadingButton
          variant="contained"
          size="small"
          sx={{ height: "fit-content", mt: 8, ml: 3 }}
          type="submit"
          onClick={() => {
            addStep();
          }}>
          Add Step
        </LoadingButton>
      </Grid>
      <Button
        variant="contained"
        size="medium"
        disabled={
          //!(
          //  isAllPhotosTaken(postExperimentImages, experiment.slide_count, 0) &&
          //  (!hasSecondaryAnalyte ||
          //    isAllPhotosTaken(postExperimentImages, experiment.slide_count, 1))
          //)
          false
        }
        type="submit"
        sx={{
          alignSelf: "baseline",
          width: "fit-content",
          marginLeft: "auto",
        }}
        onClick={handleDoneExperiment}>
        Complete Experiment
      </Button>
      <Backdrop open={isImageLoading}>
        <Loader />
      </Backdrop>
    </Box>
  );
};

export default AdHocExperiment;
