import { z } from 'zod';

export enum ExperimentStatus {
  NEW = 'new',
  DEFINED = 'defined',
  BEGUN = 'begun',
  PREPARED = 'prepared',
  COMPLETE = 'complete',
  AD_HOC = 'ad-hoc',
}

export const Rectangle = z.object({
  x: z.number(),
  y: z.number(),
  width: z.number(),
  height: z.number(),
});
export type Rectangle = z.infer<typeof Rectangle>;

export const Color = z.object({
  red: z.number(),
  green: z.number(),
  blue: z.number(),
  red_sigma: z.number(),
  green_sigma: z.number(),
  blue_sigma: z.number(),
  delta: z.number().optional().nullable(),
  entropy: z.number(),
});
export type Color = z.infer<typeof Color>;

const SlideImage = z.object({
  id: z.number().optional(),
  lab: z.number().optional(),
  slide_id: z.string().uuid().optional(),
  created_on: z.date().optional(),
  protocol_stage: z.nativeEnum(ExperimentStatus),
  step_index: z.number().optional(), // preparation and analysis stages can each have an array of steps
  image_location: z.string().optional(),
  crop: Rectangle.nullable().optional(),
  color_rectangles: Rectangle.array().optional(),
  color_values: Color.array().nullable().optional(),
  color_deltas: z.number().array().nullable().optional(),
});

type SlideImage = z.infer<typeof SlideImage>;
export default SlideImage;
