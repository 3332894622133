import process from "process";
import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import { RootState } from "app/store";
import { logout, setUser } from "features/user/userSlice";

export enum Tags {
  WAFER_BATCHES = "Wafer Batches",
  WAFER = "Wafer",
  USERS = "Users",
  EXPERIMENT = "Experiment",
  SLIDE_IMAGE = "Slide Image",
  LABS = "Labs",
  LABUSERS = "LabUsers",
}

const env = process.env.NODE_ENV;

console.log("Environment is ", env);
export const BASE_URL =
  env == "development" ? "http://localhost:8080" : "https://pavonislab.com";

export const baseQuery = fetchBaseQuery({
  baseUrl: `${BASE_URL}/api/v1`,
  prepareHeaders: (headers, { getState }) => {
    // By default, if we have a token in the store, let's use that for authenticated requests
    const token = (getState() as RootState).user.user?.token;

    if (token) {
      headers.set("Authorization", `Bearer:${token}`);
    }

    return headers;
  },
});

const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (
    result.error &&
    // @ts-expect-error
    (result.error.status === 401 || result.error?.originalStatus === 404)
  ) {
    // try to get a new token
    const refreshResult = await baseQuery("/refresh_token", api, extraOptions);
    const { user } = (api.getState() as RootState).user;

    if (refreshResult.data && user) {
      // store the new token
      api.dispatch(setUser({ ...user, token: refreshResult.data as string }));
      // retry the initial query
      result = await baseQuery(args, api, extraOptions);
    } else {
      api.dispatch(logout());
    }
  }

  return result;
};

export const api = createApi({
  baseQuery: baseQueryWithReauth,
  // this api has endpoints injected in adjacent files
  endpoints: () => ({}),
  tagTypes: Object.values(Tags),
});
