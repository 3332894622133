/* eslint-disable @typescript-eslint/no-unused-vars */
import { isArray } from "lodash";
import { FC, useState } from "react";
import {
  FieldValues,
  SubmitHandler,
  useForm,
  Controller,
} from "react-hook-form";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import { LoadingButton } from "@mui/lab";
import { MenuItem, Select } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Step } from "@pavonis/pavonis-api/src/api/experiments/experiment.model";
import SlideImage, {
  Rectangle,
  Color,
} from "@pavonis/pavonis-api/src/api/experiments/slides/images/image_model";
import BorderedBox from "components/BorderedBox";
import FileUpload from "components/FileUpload";
import { AD_HOC_STEP } from "features/experiments/constants";
import { renderMetricElements } from "features/experiments/helpers/renderMetricElements";
import { MetricElement, SlideImageDictValue } from "features/experiments/types";
import { BASE_URL } from "services/api";
import WebCamera from "../../WebCamera";

interface Props {
  stepN: number;
  uploadData: (values: Step, step: number) => void;
  onPictureTake: (newPhoto: string, stepIndex: number) => Promise<void>;
  onImageUpdate: (rectangles: Rectangle[], stepIndex: number) => void;
  onImageDelete: (stepIndex: number) => void;
  isDataLoading: boolean;
  photo: SlideImage | undefined;
  step?: Step;
  selected: boolean;
  setSelected: () => void;
  activeSpots: number[];
  colors?: Color[];
}

const StepWrapper: FC<Props> = ({
  stepN,
  photo,
  isDataLoading,
  uploadData,
  onPictureTake,
  onImageUpdate,
  onImageDelete,
  step,
  selected,
  setSelected,
  activeSpots,
  colors,
}) => {
  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({ defaultValues: step });

  //  const [isEditMode, setIsEditMode] = useState(
  //    !Object.keys(step ?? {}).length && !photo,
  //  );
  const [isCameraModalOpen, setIsCameraModalOpen] = useState(false);

  const onCameraClick = () => {
    setIsCameraModalOpen(true);
  };

  const handleFileUpload = (file: File) => {
    const reader = new FileReader();

    reader.addEventListener("load", () => {
      if (typeof reader?.result !== "string") return;

      handleImageCapture(reader?.result);
    });

    reader.readAsDataURL(file);
  };

  const onSubmit: SubmitHandler<FieldValues> = values => {
    if (selected) {
      uploadData(values as Step, stepN - 1);
    }
    //setIsEditMode(prev => !prev);
  };

  const handleImageCapture = async (image: string) => {
    await handleSubmit(async values => {
      onSubmit(values);

      await onPictureTake(image, stepN);
      setIsCameraModalOpen(true);
    })();
  };

  const getMetricElement = (index: number) => {
    const s = {
      ...AD_HOC_STEP[index],
      isDisabled: !selected,
    };

    return (
      <Box
        key={s.name}
        pl={0}
        width={"100%"}
        sx={{ opacity: s.isDisabled ? 0.5 : 1 }}>
        <Typography variant="body2" mb={1} pl={0}>
          {s.label}
        </Typography>
        <FormControlLabel
          sx={{ flex: 1 }}
          label={
            isArray(s.units) ? (
              <Controller
                control={control}
                name={`${s.name as "exposureTime" | "concentration"}Units`}
                defaultValue={s.measurement}
                render={({ field: { onChange, value } }) => (
                  <Select size="small" value={value} onChange={onChange}>
                    {s.units?.map(unit => (
                      <MenuItem key={unit} value={unit}>
                        {unit}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            ) : (
              <Typography variant="body2" ml={1}>
                {s.measurement}
              </Typography>
            )
          }
          control={renderMetricElements(
            s as MetricElement,
            register,
            errors,
            control,
          )}
        />
      </Box>
    );
  };

  return (
    <Grid item md={4}>
      <BorderedBox
        title={`Step ${stepN}`}
        pt={"1.5rem"}
        component="form"
        onClick={_ => setSelected()}
        onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item md={12}>
            {getMetricElement(0)}
          </Grid>
          <Grid item md={6}>
            {getMetricElement(1)}
          </Grid>
          <Grid item md={6}>
            {getMetricElement(2)}
          </Grid>
          <Grid item md={12}>
            {getMetricElement(3)}
          </Grid>
          {photo ? (
            <Grid item md={10}>
              <Button
                variant="outlined"
                size="small"
                color="inherit"
                startIcon={<InsertPhotoIcon />}
                onClick={onCameraClick}>
                Preview photo
              </Button>
            </Grid>
          ) : (
            <>
              <Grid item md={4}>
                <Button
                  variant="outlined"
                  size="small"
                  color="inherit"
                  sx={{ alignSelf: "flex-start", opacity: !selected ? 0.5 : 1 }}
                  startIcon={<CameraAltIcon />}
                  disabled={!selected}
                  onClick={onCameraClick}>
                  Take photo
                </Button>
              </Grid>

              <Grid item md={6}>
                <FileUpload
                  onFileChange={handleFileUpload}
                  disabled={!selected}
                />
              </Grid>
            </>
          )}
          <Grid item md={2} alignItems={"flex-end"}>
            <LoadingButton
              loading={isDataLoading}
              variant="contained"
              size="small"
              disabled={!selected}
              sx={{ minHeight: "40px" }}
              type="submit">
              {"Apply"}
            </LoadingButton>
          </Grid>
        </Grid>
      </BorderedBox>

      <WebCamera
        isOpen={isCameraModalOpen}
        handleClose={() => setIsCameraModalOpen(false)}
        handleImageCapture={handleImageCapture}
        handleImageDelete={() => onImageDelete(stepN)}
        handleImageUpdate={rect => onImageUpdate(rect, stepN)}
        photo={photo && BASE_URL + photo?.image_location}
        rectangles={photo?.color_rectangles}
        colors={colors}
      />
    </Grid>
  );
};

export default StepWrapper;
