export enum BaseRoutes {
  Base = "/",
  Login = "/login",
  Experiment = "/experiments/:id",
  Users = "/labs/:id",
  NotFound = "*",
}

export enum SidebarRoutes {
  Experiments = "/experiments",
  Labs = "/labs",
}
