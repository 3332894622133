import moment from "moment";
import { useCallback, useMemo } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { ExperimentStatus } from "@pavonis/pavonis-api/src/api/experiments/slides/images/image_model";
import Loader from "@pavonis/pavonis-hub/src/components/Loader";
import PageTemplate from "@pavonis/pavonis-hub/src/components/PageTemplate";

import { useGetExperimentQuery } from "services/experiments";
import AdHocExperiment from "../components/AdHocExperiment";
import CompletedExperiment from "../components/CompletedExperiment";
import DefinedExperiment from "../components/DefinedExperiment";
import NewExperiment from "../components/NewExperiment";
import PreparedExperiment from "../components/PreparedExperiment";

const STATUS_NAMES = {
  [ExperimentStatus.NEW]: "NEW",
  [ExperimentStatus.BEGUN]: "PREPARATION BEGUN",
  [ExperimentStatus.DEFINED]: "SLIDE PREPARATION",
  [ExperimentStatus.PREPARED]: "CONDUCT EXPERIMENT",
  [ExperimentStatus.COMPLETE]: "COMPLETE",
  [ExperimentStatus.AD_HOC]: "AD HOC",
};

const Experiment = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();

  const { data, isLoading } = useGetExperimentQuery(+(id ?? 0));

  const tableId = location?.state?.tableId;

  const renderContent = useCallback(() => {
    if (!data) {
      return;
    }

    switch (data.status) {
      case ExperimentStatus.NEW:
      case ExperimentStatus.DEFINED:
        return <NewExperiment experiment={data} />;
      case ExperimentStatus.BEGUN:
        return <DefinedExperiment experiment={data} />;
      case ExperimentStatus.PREPARED:
        return <PreparedExperiment experiment={data} />;
      case ExperimentStatus.COMPLETE:
        return <CompletedExperiment experiment={data} />;
      case ExperimentStatus.AD_HOC:
        return <AdHocExperiment experiment={data} />;
    }
  }, [data]);

  const experimentTitle = useMemo(() => {
    if (!data || !tableId) return "";

    return `Experiment ${moment(data.created_date).format(
      "YYYYMMDD",
    )}${`-${tableId}`} (${STATUS_NAMES[data.status]})`;
  }, [data, tableId]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <PageTemplate
      hasBackBtn
      title={experimentTitle}
      onBackBtnClick={() => {
        navigate(-1);
      }}>
      {renderContent()}
    </PageTemplate>
  );
};

export default Experiment;
