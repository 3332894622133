//import download from "downloadjs";
import moment from "moment";
import { useCallback, useMemo, useState } from "react";
import { generatePath, useNavigate } from "react-router-dom";

import CloudDownload from "@mui/icons-material/CloudDownload";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ScienceIcon from "@mui/icons-material/Science";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import { Menu, MenuItem, Tooltip } from "@mui/material";
import {
  DataGrid,
  GridActionsCellItem,
  GridColumns,
  GridRowId,
  GridRowModesModel,
} from "@mui/x-data-grid";

import Experiment, {
  EXPERIMENT_TYPES,
  Step,
  Treatment,
} from "@pavonis/pavonis-api/src/api/experiments/experiment.model";
import { ExperimentStatus } from "@pavonis/pavonis-api/src/api/experiments/slides/images/image_model";
import Loader from "@pavonis/pavonis-hub/src/components/Loader";
import PageTemplate from "@pavonis/pavonis-hub/src/components/PageTemplate";
import Toolbar from "@pavonis/pavonis-hub/src/components/Toolbar";
import { useAppSelector } from "app/hooks";
import { BaseRoutes } from "features/navigation/types";

import {
  useCreateExperimentMutation,
  useGetExperimentsQuery,
  useUpdateExperimentMutation,
  useArchiveExperimentMutation,
  downloadCSV,
} from "services/experiments";

const getStatusLabel = (status: ExperimentStatus) => {
  switch (status) {
    case ExperimentStatus.DEFINED:
      return "Ready for Slide Prep";
    case ExperimentStatus.BEGUN:
      return "Preparation Begun";
    case ExperimentStatus.PREPARED:
      return "Ready for Experiment";
    case ExperimentStatus.COMPLETE:
      return "Complete";
    case ExperimentStatus.AD_HOC:
      return "Ad Hoc";
    default:
      return "Being Defined";
  }
};

const Experiments: React.FC = () => {
  const navigate = useNavigate();

  const { data: experiments, isLoading } = useGetExperimentsQuery();
  const [createExperiment] = useCreateExperimentMutation();
  const [updateExperiment] = useUpdateExperimentMutation();
  const [archiveExperiment] = useArchiveExperimentMutation();

  const { user } = useAppSelector(state => state.user);

  const rows: Experiment[] = useMemo(() => experiments ?? [], [experiments]);

  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});

  const [addDropdown, setAddDropdown] = useState<null | HTMLElement>(null);
  const handleAddBtnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAddDropdown(event.currentTarget);
  };

  const onExperimentAdd = async (
    status: ExperimentStatus,
    slideCount: number,
  ) => {
    if (!user) return;

    const newExperiment: Experiment = {
      description: "",
      scientist: user.id,
      slide_count: slideCount,
      slide_circle_count: 8,
      status,
      lab: user.lab_id,
      kind: EXPERIMENT_TYPES.STRUCTURED,
    };

    // Set up ad-hoc protocol
    if (status == ExperimentStatus.AD_HOC) {
      newExperiment.kind = EXPERIMENT_TYPES.AD_HOC;
      const treatment: Treatment = {
        preparation: Array.from(
          { length: newExperiment.slide_circle_count ?? 8 },
          () => {
            const t: Step = { isBlank: true };

            return t;
          },
        ),
        analytes: [],
      };

      newExperiment.protocol = {
        steps: [treatment],
      };
    }

    const { experiment } = await createExperiment(newExperiment).unwrap();

    if (!experiment) return;

    navigate(
      generatePath(BaseRoutes.Experiment, { id: String(experiment.id) }),
      {
        state: { tableId: rows.length + 1 },
      },
    );
  };

  const handleNavigation = useCallback(
    (id: string, tableId: GridRowId) => {
      navigate(generatePath(BaseRoutes.Experiment, { id }), {
        state: { tableId },
      });
    },
    [navigate],
  );

  const handleBeginPreparation = useCallback(
    async (id: number) => {
      const experimentId = rows.findIndex(e => e.id === id);

      if (experimentId === -1) return;

      const experiment = rows[experimentId];

      if (experiment.status !== ExperimentStatus.BEGUN) {
        await updateExperiment({
          id,
          experiment: {
            ...experiment,
            status: ExperimentStatus.BEGUN,
          },
        });
      }

      handleNavigation(`${id}`, experimentId + 1);
    },
    [rows, updateExperiment, handleNavigation],
  );

  const handleEdit = useCallback(
    async (id: number) => {
      const experimentId = rows.findIndex(e => e.id === id);

      if (experimentId === -1) return;

      await updateExperiment({
        id,
        experiment: {
          ...rows[experimentId],
          status: ExperimentStatus.DEFINED,
        },
      });

      navigate(generatePath(BaseRoutes.Experiment, { id: String(id) }), {
        state: { tableId: experimentId + 1 },
      });
    },
    [rows, updateExperiment, navigate],
  );

  const handleArchive = useCallback(
    async (id: number) => {
      await archiveExperiment({ id: id });
    },
    [archiveExperiment],
  );

  const handleDownloadCSV = async (e: Experiment) => {
    downloadCSV(e);
  };

  const handleCopy = useCallback(
    async (copiedExperiment: Experiment) => {
      if (!user) return;

      // TODO: make slides photos empty
      const newExperiment: Experiment = {
        ...copiedExperiment,
        scientist: user.id,
        status: ExperimentStatus.NEW,
        lab: user.lab_id,
        replicates_experiment: copiedExperiment.id,
      };
      const { experiment } = await createExperiment(newExperiment).unwrap();

      if (!experiment) return;

      navigate(
        generatePath(BaseRoutes.Experiment, { id: String(experiment.id) }),
        {
          state: { tableId: rows.length + 1 },
        },
      );
    },
    [user, createExperiment, navigate, rows.length],
  );

  const columns: GridColumns = useMemo(
    () => [
      {
        field: "id",
        headerName: "ID",
        width: 120,
        valueGetter: params =>
          `${moment(params.row.created_at).format("YYYYMMDD") || ""}-${
            params.id
          }`,
      },
      {
        field: "description",
        headerName: "Description",
        sortable: false,
        flex: 1,
      },
      {
        field: "experiment_created_date",
        headerName: "Date",
        width: 100,
        valueGetter: params =>
          `${
            moment(params.row.experiment_created_date).format("YYYY/MM/DD") ||
            ""
          }`,
      },
      {
        field: "status",
        headerName: "Status",
        width: 200,
        sortable: false,
        valueGetter: params => getStatusLabel(params.value),
      },

      {
        field: "actions",
        type: "actions",
        headerName: "Actions",
        width: 150,
        cellClassName: "actions",
        getActions: ({ id, row }) => {
          switch (row.status) {
            case ExperimentStatus.DEFINED:
              return [
                <GridActionsCellItem
                  key={id}
                  icon={
                    <Tooltip title={"Edit"}>
                      <EditIcon />
                    </Tooltip>
                  }
                  label="Edit"
                  color="inherit"
                  onClick={async () => {
                    await handleEdit(row.id);
                  }}
                />,
                <GridActionsCellItem
                  key={id}
                  icon={
                    <Tooltip title={"Prepare Slide"}>
                      <ScienceIcon />
                    </Tooltip>
                  }
                  label="Prepare Slide"
                  color="inherit"
                  onClick={() => handleBeginPreparation(row.id)}
                />,
                <GridActionsCellItem
                  key={id}
                  icon={
                    <Tooltip title={"Archive Experiment"}>
                      <DeleteIcon />
                    </Tooltip>
                  }
                  label="Archive Experiment"
                  color="inherit"
                  onClick={() => handleArchive(row.id)}
                />,
              ];
            case ExperimentStatus.BEGUN:
              return [
                <GridActionsCellItem
                  key={id}
                  icon={
                    <Tooltip title={"Continue Slide Preparation"}>
                      <ScienceIcon />
                    </Tooltip>
                  }
                  label="Prepare Slide"
                  color="inherit"
                  onClick={() => handleNavigation(row.id, id)}
                />,
                <GridActionsCellItem
                  key={id}
                  icon={
                    <Tooltip title={"Archive Experiment"}>
                      <DeleteIcon />
                    </Tooltip>
                  }
                  label="Archive Experiment"
                  color="inherit"
                  onClick={() => handleArchive(row.id)}
                />,
              ];
            case ExperimentStatus.PREPARED:
              return [
                <GridActionsCellItem
                  key={id}
                  icon={
                    <Tooltip title={"Conduct Experiment"}>
                      <ScienceIcon />
                    </Tooltip>
                  }
                  label="Conduct Experiment"
                  color="inherit"
                  onClick={() => handleNavigation(row.id, id)}
                />,
                <GridActionsCellItem
                  key={id}
                  icon={
                    <Tooltip title={"Archive Experiment"}>
                      <DeleteIcon />
                    </Tooltip>
                  }
                  label="Archive Experiment"
                  color="inherit"
                  onClick={() => handleArchive(row.id)}
                />,
              ];
            case ExperimentStatus.COMPLETE:
              return [
                <GridActionsCellItem
                  key={id}
                  icon={
                    <Tooltip title={"Download CSV"}>
                      <CloudDownload />
                    </Tooltip>
                  }
                  label="Download CSV"
                  color="inherit"
                  onClick={() => handleDownloadCSV(row)}
                />,
                <GridActionsCellItem
                  key={id}
                  icon={
                    <Tooltip title={"Report"}>
                      <TextSnippetIcon />
                    </Tooltip>
                  }
                  label="Report"
                  color="inherit"
                  onClick={() => handleNavigation(row.id, id)}
                />,
                <GridActionsCellItem
                  key={id}
                  icon={
                    <Tooltip title={"Copy"}>
                      <ContentCopyIcon />
                    </Tooltip>
                  }
                  label="Copy"
                  color="inherit"
                  onClick={async () => {
                    await handleCopy(row as Experiment);
                  }}
                />,
                <GridActionsCellItem
                  key={id}
                  icon={
                    <Tooltip title={"Archive Experiment"}>
                      <DeleteIcon />
                    </Tooltip>
                  }
                  label="Archive Experiment"
                  color="inherit"
                  onClick={() => handleArchive(row.id)}
                />,
              ];
            case ExperimentStatus.AD_HOC:
              return [
                <GridActionsCellItem
                  key={id}
                  icon={
                    <Tooltip title={"Define Experiment freely"}>
                      <ScienceIcon />
                    </Tooltip>
                  }
                  label="Define Experiment"
                  color="inherit"
                  onClick={() => handleNavigation(row.id, id)}
                />,
                <GridActionsCellItem
                  key={id}
                  icon={
                    <Tooltip title={"Archive Experiment"}>
                      <DeleteIcon />
                    </Tooltip>
                  }
                  label="Archive Experiment"
                  color="inherit"
                  onClick={() => handleArchive(row.id)}
                />,
              ];
            default:
              return [
                <GridActionsCellItem
                  key={id}
                  icon={
                    <Tooltip title={"Define Experiment"}>
                      <EditIcon />
                    </Tooltip>
                  }
                  label="Define Experiment"
                  color="inherit"
                  onClick={() => handleNavigation(row.id, id)}
                />,
                <GridActionsCellItem
                  key={id}
                  icon={
                    <Tooltip title={"Archive Experiment"}>
                      <DeleteIcon />
                    </Tooltip>
                  }
                  label="Archive Experiment"
                  color="inherit"
                  onClick={() => handleArchive(row.id)}
                />,
              ];
          }
        },
      },
    ],
    [
      handleArchive,
      handleEdit,
      handleNavigation,
      handleCopy,
      handleBeginPreparation,
    ],
  );

  return (
    <PageTemplate title="Experiments">
      {isLoading ? (
        <Loader />
      ) : (
        <DataGrid
          //getDetailPanelHeight={() => "auto"}
          sx={{ height: "calc(100% - 34px - 1rem)" }}
          rows={rows}
          columns={columns}
          components={{
            Toolbar,
          }}
          componentsProps={{
            toolbar: {
              addBtnText: "Add New",
              handleAdd: handleAddBtnClick,
              children: (
                <Menu
                  anchorEl={addDropdown}
                  open={!!addDropdown}
                  onClose={() => {
                    setAddDropdown(null);
                  }}>
                  <MenuItem
                    onClick={() => onExperimentAdd(ExperimentStatus.NEW, 5)}>
                    Experiment
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      onExperimentAdd(ExperimentStatus.AD_HOC, 1);
                    }}>
                    Ad Hoc Experiment
                  </MenuItem>
                </Menu>
              ),
            },
          }}
          experimentalFeatures={{ newEditingApi: true }}
          rowModesModel={rowModesModel}
          onRowModesModelChange={newModel => setRowModesModel(newModel)}
        />
      )}
    </PageTemplate>
  );
};

export default Experiments;
