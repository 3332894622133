/* eslint-disable @typescript-eslint/no-unused-vars */
import { isEmpty, isNil } from "lodash";
import React from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Box,
  Collapse,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Experiment from "@pavonis/pavonis-api/src/api/experiments/experiment.model";
import SlideImage, {
  ExperimentStatus,
} from "@pavonis/pavonis-api/src/api/experiments/slides/images/image_model";
import { BASE_URL } from "services/api";

const SlideSection = (
  experiment: Experiment,
  images: SlideImage[],
  slideNumber: number,
) => {
  const [open, setOpen] = React.useState(false);

  const image = images[slideNumber];

  if (isNil(image) || isEmpty(images))
    return (
      <TableRow>
        <TableCell width={1} />
        <TableCell width={150}>
          <Typography paddingBottom={0} variant="h6">
            Slide {slideNumber + 1}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography variant="h5">
            There were no images for this slide.
          </Typography>
        </TableCell>
      </TableRow>
    );

  const colors = image.color_values;
  const deltas = image.color_deltas;
  const image_url = BASE_URL + image.image_location;
  const columns = Array(experiment.slide_circle_count).fill(0);

  console.log("In slide Section", slideNumber);

  console.log("colors is ", colors);
  if (isNil(colors)) return;

  return [
    <TableRow key={image.id + "1"} sx={{ "& > *": { borderBottom: "unset" } }}>
      <TableCell width={1}>
        <IconButton
          aria-label="expand row"
          size="small"
          onClick={() => setOpen(!open)}>
          {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
      </TableCell>
      <TableCell width={150}>
        <Typography paddingBottom={0} variant="h6">
          Slide {slideNumber + 1}
        </Typography>
      </TableCell>
      <TableCell align="left">
        <img src={image_url} height={50} />
      </TableCell>
    </TableRow>,
    <TableRow key={image.id + "2"}>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={3}>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Box sx={{ ml: 2, mt: 0 }}>
            <Table
              sx={{ minWidth: 650, maxWidth: 1080 }}
              aria-label="Preparation Color Values">
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell align="right">Red</TableCell>
                  <TableCell align="right">Green</TableCell>
                  <TableCell align="right">Blue</TableCell>
                  <TableCell align="right">DeltaC</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {columns.map((c, idx) => (
                  <TableRow key={idx}>
                    <TableCell>Spot {idx + 1} </TableCell>
                    <TableCell align="right">
                      {colors[idx]?.red.toFixed(1) || 0}
                    </TableCell>
                    <TableCell align="right">
                      {colors[idx]?.green.toFixed(1) || 0}
                    </TableCell>
                    <TableCell align="right">
                      {colors[idx]?.blue.toFixed(1) || 0}
                    </TableCell>
                    <TableCell align="right">
                      {deltas
                        ? deltas[idx]?.toFixed(1) || 0
                        : "No control found"}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </Collapse>
      </TableCell>
    </TableRow>,
  ];
};

export default SlideSection;
