/* eslint-disable prettier/prettier */
import { isArray, isNil } from "lodash";
import { useMemo } from "react";
import {
  Control,
  Controller,
  FieldErrors,
  FieldValues,
  UseFormRegister,
  UseFormWatch,
} from "react-hook-form";
import { transformName } from "utils";
import { FormControlLabel, MenuItem, Select, Typography } from "@mui/material";
import { Box } from "@mui/system";

import { ANALYTE_STEPS, STEPS } from "features/experiments/constants";
import { renderMetricElements } from "features/experiments/helpers/renderMetricElements";
import { MetricElement, TreatmentInputs } from "features/experiments/types";

import styles from "./SpotMetrics.module.css";

interface Props {
  headerInput: MetricElement;
  register: UseFormRegister<FieldValues>;
  watch: UseFormWatch<FieldValues>;
  control: Control;
  errors: FieldErrors<FieldValues>;
  isEditMode: boolean;
  hasNoTreatment: boolean;
}

const SpotMetrics: React.FC<Props> = ({
  headerInput,
  register,
  watch,
  control,
  errors,
  isEditMode,
  hasNoTreatment,
}) => {
  const [name, type] = watch([
    headerInput.name,
    transformName("type", headerInput.name),
  ]);
  const steps = useMemo(() => {
    const isAnalyte = headerInput.name !== TreatmentInputs.PREPARATION;

    if (isAnalyte && type !== "Purified Protein" && !isNil(type)) {
      return [headerInput, ...ANALYTE_STEPS];
    }

    return [headerInput, ...(isAnalyte ? [ANALYTE_STEPS[0]] : []), ...STEPS];
  }, [headerInput, type]);

  return (
    <Box
      display={"flex"}
      flexDirection="column"
      gap={2}
      mt={2}
      flex={"1"}
      className={styles.spotMetrics}>
      {steps.map((m, idx) => {
        const isDisabled = name || hasNoTreatment;
        const modifiedInput =
          idx > 0
            ? {
              ...m,
              name: transformName(m.name, headerInput.name),
              isDisabled: isDisabled || !isEditMode,
              registerOptions: {
                required: isDisabled ? false : m.registerOptions?.required,
              },
            }
            : {
              ...m,
              isDisabled:
                !isEditMode ||
                (hasNoTreatment &&
                  headerInput.name !== TreatmentInputs.PREPARATION),
            };

        return (
          <Box
            key={modifiedInput.name}
            display={"flex"}
            alignItems="center"
            justifyContent={"space-between"}
            pl={0}
            sx={{ opacity: modifiedInput.isDisabled ? 0.5 : 1 }}>
            <Typography variant="body2" flex={1}>
              {modifiedInput.label}
            </Typography>
            <FormControlLabel
              sx={{ flex: 1 }}
              label={
                isArray(modifiedInput.units) ? (
                  <Controller
                    control={control}
                    name={`${modifiedInput.name}Units`}
                    defaultValue={modifiedInput.measurement}
                    render={({ field: { onChange, value } }) => (
                      <Select
                        size="small"
                        value={value}
                        onChange={onChange}
                      >
                        {modifiedInput.units?.map(unit => (
                          <MenuItem key={unit} value={unit}>
                            {unit}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                )

                  : (
                    <Typography variant="body2" ml={1}>
                      {modifiedInput.measurement}
                    </Typography>
                  )
              }
              control={renderMetricElements(
                modifiedInput as MetricElement,
                register,
                errors,
                control,
              )}
            />
          </Box>
        );
      })}
    </Box>
  );
};

export default SpotMetrics;
