import {
  useCallback,
  useMemo,
  useState,
  //useCallback
} from "react";
import {
  useNavigate,
  //generatePath,
  // useNavigate,
  useParams,
} from "react-router-dom";
import ArchiveIcon from "@mui/icons-material/Archive";
import Unarchive from "@mui/icons-material/Unarchive";
import { Tooltip } from "@mui/material";
import {
  // GridRowId,
  GridColumns,
  DataGrid,
  GridActionsCellItem,
} from "@mui/x-data-grid";
//import { DataGridPro } from "@mui/x-data-grid-pro";
import User from "@pavonis/pavonis-api/src/api/users/user.model";
import Loader from "@pavonis/pavonis-hub/src/components/Loader";
import PageTemplate from "@pavonis/pavonis-hub/src/components/PageTemplate";
import Toolbar from "@pavonis/pavonis-hub/src/components/Toolbar";
//import { BaseRoutes } from "features/navigation/types";
//import { IUser } from "features/user/types";
import { useAppSelector } from "app/hooks";
import { useGetLabUsersQuery, useGetLabsQuery } from "services/labs";
import { useDeleteUserMutation, useRestoreUserMutation } from "services/user";
import AddUserModal from "../components/AddUserModal";

const LabUsers: React.FC = () => {
  const { user: currentuser } = useAppSelector(state => state.user);
  const { id } = useParams();
  const labid = id ? parseInt(id) : 0;
  const { data: users, isLoading } = useGetLabUsersQuery(parseInt(id ?? "0"));
  const [archiveUser] = useDeleteUserMutation();
  const [restoreUser] = useRestoreUserMutation();
  const { data: labs } = useGetLabsQuery();

  const lab = labs?.find(l => {
    console.log(`Comparing ${l.id} to ${labid}`);

    return l.id === labid;
  });

  const labname = lab?.name;

  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const navigate = useNavigate();
  const rows: User[] = useMemo(() => users ?? [], [users]);

  const handleArchive = useCallback(
    async (userid: number, archive: boolean) => {
      console.log(`Archiving user ${userid}, ${archive}`);
      if (archive) {
        console.log(`Archiving user ${userid}, ${archive}`);
        await archiveUser(userid);
      } else {
        console.log(`Restoring user ${userid}, ${archive}`);
        await restoreUser(userid);
      }
    },
    [archiveUser, restoreUser],
  );

  const columns: GridColumns = useMemo(
    () => [
      {
        field: "firstname",
        headerName: "First Name",
        width: 140,
        editable: true,
      },
      {
        field: "lastname",
        headerName: "Last Name",
        width: 160,
        editable: true,
      },
      {
        field: "email",
        headerName: "Email",
        sortable: false,
        flex: 1,
      },
      {
        field: "archived",
        headerName: "Status",
        valueGetter: params => {
          if (!params.value) {
            return "Active";
          }

          return params.value ? "Suspended" : "Active";
        },
        sortable: false,
        flex: 1,
      },
      {
        field: "actions",
        type: "actions",
        headerName: "Actions",
        width: 150,
        getActions: row => {
          const rowid = row.id;
          const user = row.row;
          const isCurrentUser = currentuser && currentuser.id === user.id;

          if (isCurrentUser) {
            return [];
          }
          if (user.archived) {
            return [
              <GridActionsCellItem
                key={rowid}
                icon={
                  <Tooltip title={"Restore User"}>
                    <Unarchive />
                  </Tooltip>
                }
                label="Restore"
                color="inherit"
                onClick={() => handleArchive(user.id, false)}
              />,
            ];
          }

          return [
            <GridActionsCellItem
              key={rowid}
              icon={
                <Tooltip title={"Suspend User"}>
                  <ArchiveIcon />
                </Tooltip>
              }
              label="Suspend User"
              color="inherit"
              onClick={() => handleArchive(user.id, true)}
            />,
          ];
        },
      },
    ],
    [currentuser, handleArchive],
  );

  return (
    <PageTemplate
      hasBackBtn
      onBackBtnClick={() => {
        navigate(-1);
      }}
      title={`${labname} Users`}>
      {isLoading ? (
        <Loader />
      ) : (
        <DataGrid
          rows={rows}
          columns={columns}
          sx={{ height: "calc(100% - 34px - 1rem)" }}
          editMode="row"
          components={{
            Toolbar,
          }}
          componentsProps={{
            toolbar: {
              addBtnText: "Add New",
              handleAdd: () => {
                setIsAddModalOpen(true);
              },
            },
          }}
        />
      )}

      <AddUserModal
        isOpen={isAddModalOpen}
        closeModal={() => {
          setIsAddModalOpen(false);
        }}
        labId={id}
      />
    </PageTemplate>
  );
};

export default LabUsers;
