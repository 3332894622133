/**
 * Type predicate to narrow error to an object with a string 'error' property
 */
export const isErrorWithMessage = (
  error: unknown,
): error is { data: { error: string } } => {
  if (
    !(
      typeof error === "object" &&
      error != null &&
      "data" in error &&
      typeof (error as any).data === "object"
    )
  )
    return false;

  const { data } = error as any;

  return (
    data != null && "error" in data && typeof (data as any).error === "string"
  );
};
