/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback, useRef } from "react";
import Webcam from "react-webcam";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton, Modal, Typography } from "@mui/material";
import { Box } from "@mui/system";
import {
  Rectangle,
  Color,
} from "@pavonis/pavonis-api/src/api/experiments/slides/images/image_model";
import Preview from "./Preview";

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  handleImageCapture: (p: string) => void;
  handleImageDelete: () => void;
  handleImageUpdate: (rectangles: Rectangle[]) => void;
  photo?: string | null;
  rectangles?: Rectangle[];
  colors?: Color[];
}

const WebCamera: React.FC<Props> = ({
  isOpen,
  handleClose,
  handleImageCapture,
  handleImageDelete,
  handleImageUpdate,
  rectangles,
  photo,
  colors,
}) => {
  const webcamRef = useRef<Webcam>(null);

  const takePicture = useCallback(() => {
    if (!webcamRef?.current) return;

    const pictureSrc = webcamRef.current.getScreenshot();

    if (!pictureSrc) return;

    handleImageCapture(pictureSrc);

    handleClose();
  }, [handleImageCapture, handleClose]);

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
      <Box
        display={"flex"}
        justifyContent="center"
        alignItems={"center"}
        position={"relative"}
        width={"100vw"}
        height={"100vh"}
        bgcolor="#000"
        overflow="hidden">
        {photo ? (
          <>
            <Preview
              photoUrl={photo}
              handleImageDelete={handleImageDelete}
              rectangles={rectangles}
              handleImageUpdate={handleImageUpdate}
              colors={colors}
            />
          </>
        ) : (
          <>
            <Webcam
              audio={false}
              height={"100%"}
              ref={webcamRef}
              width={"100%"}
              screenshotFormat="image/jpeg"
            />
            <Box
              onClick={e => {
                e.preventDefault();
                takePicture();
              }}
              sx={{
                width: "60px",
                height: "60px",
                borderRadius: "100%",
                bgcolor: "#fff",
                position: "absolute",
                bottom: "30px",
                left: "50%",
                transform: "translateX(-50%)",
                cursor: "pointer",
                "&:hover": {
                  opacity: "0.6",
                },
              }}
            />
          </>
        )}
        <IconButton
          size="large"
          sx={{
            position: "absolute",
            top: "20px",
            right: "20px",
            backgroundColor: "#90caf9",
            "&:hover": {
              backgroundColor: "#42a5f5",
            },
          }}
          onClick={handleClose}>
          <CloseIcon color="action" />
        </IconButton>
      </Box>
    </Modal>
  );
};

export default WebCamera;
