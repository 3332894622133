import { ComponentType } from "react";
import { Navigate } from "react-router-dom";

import { useAppSelector } from "app/hooks";

import { BaseRoutes } from "../types";

interface Props {
  component: ComponentType<object>;
}

const ProtectedRoute: React.FC<Props> = ({ component: Component }) => {
  const { user } = useAppSelector(state => state.user);

  if (!user) {
    return <Navigate to={BaseRoutes.Login} replace />;
  }

  return <Component />;
};

export default ProtectedRoute;
