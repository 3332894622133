import {
  Controller,
  FieldValues,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Experiment from "@pavonis/pavonis-api/src/api/experiments/experiment.model";
import BorderedBox from "components/BorderedBox";
import CustomTextarea from "components/CustomTextarea";
import { useUpdateExperimentMutation } from "services/experiments";
import { isErrorWithMessage } from "services/helpers";
import ReportGrid from "./components/ReportGrid";

interface Props {
  experiment: Experiment;
}

const CompletedExperiment: React.FC<Props> = ({ experiment }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [updateExperiment, { isLoading, error }] =
    useUpdateExperimentMutation();

  const shouldRenderErrorMessage = error && isErrorWithMessage(error);

  const onSubmit: SubmitHandler<FieldValues> = async values => {
    if (!experiment.id) return;

    await updateExperiment({
      id: experiment.id,
      experiment: {
        ...experiment,
        notes: values.notes,
      },
    });
  };

  return (
    <Box
      pl={0}
      width={"100%"}
      display="flex"
      flexDirection={"column"}
      gap={2}
      component="form"
      onSubmit={handleSubmit(onSubmit)}>
      {experiment.notes?.length ? (
        <BorderedBox title={"Notes"}>
          <Typography>{experiment.notes}</Typography>
        </BorderedBox>
      ) : (
        <>
          <Controller
            control={control}
            name={"notes"}
            rules={{ required: false }}
            render={({ field: { onChange, value } }) => (
              <CustomTextarea
                value={value}
                onChange={onChange}
                error={!!errors.notes}
                label="Notes"
              />
            )}
          />
          {shouldRenderErrorMessage && (
            <Typography
              variant="overline"
              display="block"
              gutterBottom
              sx={{
                mb: 0,
                fontSize: "0.6rem",
                color: "#f44336",
              }}>
              {error.data.error}
            </Typography>
          )}
          <LoadingButton
            variant="contained"
            size="medium"
            type="submit"
            sx={{ alignSelf: "flex-start" }}
            loading={isLoading}>
            Submit
          </LoadingButton>
        </>
      )}
      <ReportGrid experiment={experiment} />
    </Box>
  );
};

export default CompletedExperiment;
