import { useEffect } from "react";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";

import { lowercaseFirstLetter } from "utils";
import { LoadingButton } from "@mui/lab";
import { Box, Button, TextField, Typography } from "@mui/material";
import Experiment, {
  Step,
} from "@pavonis/pavonis-api/src/api/experiments/experiment.model";

import BorderedBox from "components/BorderedBox";
import { TREATMENTS } from "features/experiments/constants";

import { setDefaultValues } from "features/experiments/helpers/setDefaultValues";
import { TreatmentInputs } from "features/experiments/types";
import SpotMetrics from "../SpotMetrics";

const INPUT_PREFIXES = Object.values(TreatmentInputs);
const FIRST_INPUT = "accession";

interface Props {
  experiment?: Experiment;
  activeSpots: number[];
  numOfSpots: number;
  uploadData: (v?: {
    preparation: { [k: string]: string | number }[];
    analytes: { [k: string]: string | number }[];
  }) => void;
  isEditMode: boolean;
  isUpdating: boolean;
}

const SpotWrapper: React.FC<Props> = ({
  experiment,
  activeSpots,
  numOfSpots,
  uploadData,
  isEditMode,
  isUpdating,
}) => {
  const title = `Spot${activeSpots.length > 1 ? "s" : ""} ${activeSpots
    .map(s => s + 1)
    .join(", ")}`;

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    control,
    reset,
  } = useForm();
  const [copyFromId, name]: unknown[] = watch([
    "copyFromId",
    TREATMENTS[0].name,
  ]);

  const onSubmit: SubmitHandler<FieldValues> = values => {
    let allSpots;
    let analytesNum = 0;

    if (isEditMode) {
      allSpots = Object.keys(values as Step).reduce(
        (accum, k) => {
          const prefix = INPUT_PREFIXES.find(s => k.includes(s));

          if (!prefix) return accum;
          const inputName = lowercaseFirstLetter(k.replace(prefix, ""));
          const data = { [inputName || "isBlank"]: values[k] };

          if (prefix === TreatmentInputs.PREPARATION) {
            return {
              ...accum,
              [prefix]: [{ ...accum[prefix]?.[0], ...data }],
            };
          }

          if (inputName === FIRST_INPUT) {
            analytesNum++;
          }

          const analytes = [...accum.analytes];

          analytes[analytesNum - 1] = {
            ...accum.analytes[analytesNum - 1],
            ...data,
          };

          return {
            ...accum,
            analytes,
          };
        },
        {
          preparation: [{}],
          analytes: [{}],
        },
      );

      // if preparation is blank for this spot => all of the analytes are blank
      if ((allSpots.preparation[0] as Step).isBlank) {
        allSpots = {
          ...allSpots,
          analytes: allSpots.analytes.map(_ => ({ isBlank: true })),
        };
      }
    }

    uploadData(allSpots);
  };

  const onCopy = () => {
    const copyFrom = +getValues("copyFromId");

    if (!experiment) return;

    setDefaultValues(experiment, copyFrom - 1, (input, value) => {
      setValue(input, value);
    });
  };

  useEffect(() => {
    if (!experiment) return;

    if (
      activeSpots.length === 1 &&
      !experiment.protocol?.steps?.[activeSpots[0]]
    ) {
      reset();

      return;
    }

    setDefaultValues(experiment, activeSpots[0], (input, value) => {
      setValue(input, value);
    });
  }, [experiment, activeSpots, setValue, reset]);

  return (
    <BorderedBox title={title}>
      <Box display={"flex"} alignItems="center" gap={2}>
        <Typography>Copy from Spot</Typography>
        <TextField
          size="small"
          type="number"
          inputProps={{ min: 1, max: numOfSpots }}
          sx={{ width: "70px" }}
          {...register("copyFromId", { valueAsNumber: true })}
        />
        <Button
          variant="outlined"
          color="inherit"
          size="small"
          sx={{ minHeight: "40px", ml: 1 }}
          disabled={!copyFromId || !isEditMode}
          onClick={onCopy}>
          Copy
        </Button>
      </Box>

      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <Box
          display={"flex"}
          justifyContent="space-between"
          gap={2}
          flexWrap="wrap">
          {TREATMENTS.map((t, index) => (
            <SpotMetrics
              key={index}
              headerInput={t}
              register={register}
              watch={watch}
              control={control}
              isEditMode={isEditMode}
              errors={errors}
              hasNoTreatment={name as boolean}
            />
          ))}
        </Box>
        <Box display={"flex"} justifyContent="flex-start">
          <LoadingButton
            loading={isUpdating}
            variant="contained"
            size="small"
            sx={{ minHeight: "40px", mt: 2 }}
            type="submit">
            {isEditMode
              ? `Apply${activeSpots.length > 1 ? " to all spots" : ""}`
              : "Edit"}
          </LoadingButton>
        </Box>
      </Box>
    </BorderedBox>
  );
};

export default SpotWrapper;
