/* eslint-disable @typescript-eslint/no-unused-vars */
import { isEqual, isNil } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, Divider } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import Experiment from "@pavonis/pavonis-api/src/api/experiments/experiment.model";
import {
  ExperimentStatus,
  Rectangle,
} from "@pavonis/pavonis-api/src/api/experiments/slides/images/image_model";
import Loader from "@pavonis/pavonis-hub/src/components/Loader";
import { RootState } from "app/store";
import {
  createImage,
  deleteImage,
  updateImage,
} from "features/experiments/slideImagesSlice";
import { SlideImageDictionary } from "features/experiments/types";
import { BASE_URL } from "services/api";
import {
  useArchiveSlideImageMutation,
  useCreateSlideImageMutation,
  useUpdateExperimentMutation,
  useUpdateSlideImageRectanglesMutation,
} from "services/experiments";
import { toFormData } from "utils/toFormData";
import Photos from "../Photos";
import Protocol from "../Protocol";
interface Props {
  experiment: Experiment;
}

const isAllPhotosTaken = (
  photos: SlideImageDictionary,
  slideCount: number,
  stepIndex: number,
) =>
  Object.values(photos).filter(photo => !isNil(photo?.[stepIndex]?.url))
    .length === slideCount;

const PreparedExperiment: React.FC<Props> = ({ experiment }) => {
  const dispatch = useDispatch();

  const hasSecondaryAnalyte = experiment.protocol?.steps?.some(step => {
    const secondaryAnalyte = step?.analytes?.[1];

    // has properties for the secondary analyte & it's not blank
    return (
      !!Object.values(secondaryAnalyte ?? {}).length &&
      !secondaryAnalyte?.isBlank
    );
  });

  const slideImages = useSelector((state: RootState) => state.slideImages);
  const experimentSlideImages = slideImages[experiment.id ?? 0];
  const postExperimentImages = useMemo(
    () => experimentSlideImages?.[ExperimentStatus.BEGUN] ?? {},
    [experimentSlideImages],
  );

  const [updateExperiment] = useUpdateExperimentMutation();
  const [createSlideImage] = useCreateSlideImageMutation();
  const [updateSlideImage] = useUpdateSlideImageRectanglesMutation();
  const [archiveSlideImage] = useArchiveSlideImageMutation();

  const [isImageLoading, setIsImageLoading] = useState(false);

  const handleSlideImageCreate = useCallback(
    async (
      newPhoto: string,
      slideId: string,
      protocolStage: ExperimentStatus,
      stepIndex: 1 | 2,
    ) => {
      if (!experiment.id) return;

      setIsImageLoading(true);

      const formData = await toFormData(newPhoto);

      const { value } = await createSlideImage({
        id: experiment.id!,
        slideId,
        protocolStage,
        file: formData,
        stepIndex,
      }).unwrap();

      if (!value || !value.image_location) return;

      const slideImage = {
        url: `${BASE_URL}${value.image_location}`,
        rectangles: value.color_rectangles ?? [],
        id: value.id,
        stepIndex,
      };

      dispatch(
        createImage({
          experimentId: experiment.id,
          slideId,
          stage: protocolStage,
          image: slideImage,
          stepIndex,
        }),
      );
      setIsImageLoading(false);
    },
    [createSlideImage, experiment.id, dispatch],
  );

  const onPrimaryAnalytePictureTake = useCallback(
    async (newPhoto: string, slideId: string) => {
      await handleSlideImageCreate(
        newPhoto,
        slideId,
        ExperimentStatus.BEGUN,
        1,
      );
    },
    [handleSlideImageCreate],
  );

  const onSecondaryAnalytePictureTake = useCallback(
    async (newPhoto: string, slideId: string) => {
      await handleSlideImageCreate(
        newPhoto,
        slideId,
        ExperimentStatus.BEGUN,
        2,
      );
    },
    [handleSlideImageCreate],
  );

  const handleSlideImageUpdate = useCallback(
    async (
      slidesImages: SlideImageDictionary,
      slideId: string,
      stage: ExperimentStatus,
      updatedRectangles: Rectangle[],
      stepIndex: 1 | 2,
    ) => {
      if (!experiment.id) return;

      const slideImage = slidesImages[slideId];
      const imageId = slideImage?.[stepIndex - 1]?.id;

      if (!slideImage || !imageId) return;

      if (isEqual(slideImage[stepIndex - 1]?.rectangles, updatedRectangles))
        return;

      setIsImageLoading(true);

      await updateSlideImage({
        id: experiment.id,
        slideId,
        imageId,
        rectangles: updatedRectangles,
      }).unwrap();

      dispatch(
        updateImage({
          experimentId: experiment.id,
          slideId,
          stage,
          stepIndex,
          updates: {
            rectangles: updatedRectangles,
          },
        }),
      );
      setIsImageLoading(false);
    },
    [experiment.id, updateSlideImage, dispatch],
  );

  const onPrimaryAnalytePictureUpdate = useCallback(
    async (slideId: string, updatedRectangles: Rectangle[]) => {
      handleSlideImageUpdate(
        postExperimentImages,
        slideId,
        ExperimentStatus.BEGUN,
        updatedRectangles,
        1,
      );
    },
    [postExperimentImages, handleSlideImageUpdate],
  );

  const onSecondaryAnalytePictureUpdate = useCallback(
    async (slideId: string, updatedRectangles: Rectangle[]) => {
      handleSlideImageUpdate(
        postExperimentImages,
        slideId,
        ExperimentStatus.BEGUN,
        updatedRectangles,
        2,
      );
    },
    [postExperimentImages, handleSlideImageUpdate],
  );

  const handleSlideImageDelete = useCallback(
    async (slideId: string, stage: ExperimentStatus, stepIndex: 1 | 2) => {
      if (!experiment.id) return;
      setIsImageLoading(true);
      const imagedict = experimentSlideImages?.[stage];
      const image = imagedict?.[slideId]?.[stepIndex - 1];

      if (image && image.id) {
        await archiveSlideImage({
          id: experiment.id,
          slideId,
          imageid: image.id,
        });
      }

      dispatch(
        deleteImage({
          experimentId: experiment.id,
          slideId,
          stepIndex,
          stage,
        }),
      );
      setIsImageLoading(false);
    },
    [dispatch, experiment.id, archiveSlideImage],
  );

  const onPrimaryAnalytePictureDelete = useCallback(
    async (slideId: string) => {
      await handleSlideImageDelete(slideId, ExperimentStatus.BEGUN, 1);
    },
    [handleSlideImageDelete],
  );

  const onSecondaryAnalytePictureDelete = useCallback(
    async (slideId: string) => {
      await handleSlideImageDelete(slideId, ExperimentStatus.BEGUN, 2);
    },
    [handleSlideImageDelete],
  );

  const handleDoneExperiment = async () => {
    if (!experiment.id) return;

    await updateExperiment({
      id: experiment.id,
      experiment: {
        ...experiment,
        status: ExperimentStatus.COMPLETE,
      },
    });
  };

  useEffect(() => {
    if (!experiment.slides) return;

    experiment.slides.forEach(slide => {
      if (!slide.images) return;

      slide.images.forEach(image => {
        if (!slide.id || !experiment.id) return;

        const slideImage = {
          url: `${BASE_URL}${image.image_location}`,
          rectangles: image.color_rectangles ?? [],
          id: image.id,
        };

        dispatch(
          createImage({
            experimentId: experiment.id,
            slideId: slide.id,
            stage: image.protocol_stage,
            image: slideImage,
            stepIndex: image.step_index as 1 | 2,
          }),
        );
      });
    });
  }, [experiment.slides, experiment.id, dispatch]);

  return (
    <Box py={3} display="flex" flexDirection={"column"} gap={3}>
      <Box display={"flex"} flexDirection="column" alignItems="center" gap={3}>
        <Protocol
          experiment={experiment}
          treatmentType={"analytes"}
          isPrimary={true}
        />
        <Photos
          title="Post-experiment Photos"
          slides={experiment.slides || []}
          images={postExperimentImages}
          onImageCapture={onPrimaryAnalytePictureTake}
          onImageDelete={onPrimaryAnalytePictureDelete}
          onImageUpdate={onPrimaryAnalytePictureUpdate}
        />
      </Box>

      <Divider />
      {hasSecondaryAnalyte && (
        <Box
          display={"flex"}
          flexDirection="column"
          alignItems="center"
          gap={3}>
          <Protocol
            experiment={experiment}
            treatmentType={"analytes"}
            isPrimary={false}
          />
          <Photos
            title="Post-experiment Photos"
            stepIndex={2}
            slides={experiment.slides || []}
            images={postExperimentImages}
            onImageCapture={onSecondaryAnalytePictureTake}
            onImageDelete={onSecondaryAnalytePictureDelete}
            onImageUpdate={onSecondaryAnalytePictureUpdate}
          />
        </Box>
      )}

      <Button
        variant="contained"
        size="medium"
        disabled={
          //!(
          //  isAllPhotosTaken(postExperimentImages, experiment.slide_count, 0) &&
          //  (!hasSecondaryAnalyte ||
          //    isAllPhotosTaken(postExperimentImages, experiment.slide_count, 1))
          //)
          false
        }
        type="submit"
        sx={{
          alignSelf: "baseline",
          width: "fit-content",
          marginLeft: "auto",
        }}
        onClick={handleDoneExperiment}>
        Complete Experiment
      </Button>
      <Backdrop open={isImageLoading}>
        <Loader />
      </Backdrop>
    </Box>
  );
};

export default PreparedExperiment;
