import { transformName } from "utils";
import Experiment, {
  Step,
} from "@pavonis/pavonis-api/src/api/experiments/experiment.model";
import { ANALYTE_STEPS, STEPS, TREATMENTS } from "../constants";
import { TreatmentInputs } from "../types";

export const setDefaultValues = (
  experiment: Experiment,
  activeSpot: number,
  setValue: (input: string, value?: string | boolean | number) => void,
) => {
  const step = experiment.protocol?.steps?.[activeSpot];
  let analytesNum = 0;

  TREATMENTS.forEach(treatment => {
    const isPreparation = treatment.name === TreatmentInputs.PREPARATION;
    const stepName: string = isPreparation
      ? TreatmentInputs.PREPARATION
      : "analytes";

    let steps = STEPS;

    if (!isPreparation) {
      analytesNum++;

      steps = [...STEPS, ...ANALYTE_STEPS];
    }
    // @ts-expect-error
    const data = step?.[stepName]?.[
      isPreparation ? 0 : analytesNum - 1
    ] as Step;

    if (!data) return;

    "isBlank" in (data ?? {}) && setValue(treatment.name, !!data?.isBlank);

    steps.forEach(input => {
      setValue(
        transformName(input.name, treatment.name),
        data?.[input.name as keyof Step],
      );
    });
  });
};
