import { useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import Box from "@mui/material/Box";

import User, {
  UserSchema,
} from "@pavonis/pavonis-api/src/api/users/user.model";
import { useCreateUserMutation } from "services/user";

interface Props {
  isOpen: boolean;
  closeModal: () => void;
  labId: string | undefined;
}

const AddUserModal = ({ isOpen, closeModal, labId }: Props) => {
  const [createUser] = useCreateUserMutation();

  const {
    register,
    formState: { errors, isSubmitSuccessful },
    reset,
    handleSubmit,
  } = useForm<User>({
    resolver: zodResolver(UserSchema),
  });

  const onSubmitHandler: SubmitHandler<User> = async values => {
    console.log("Submitting user for creation");
    values.lab_id = labId ? parseInt(labId) : 0;
    values.roles = ["researcher"];
    const res = await createUser(values);

    if ("error" in res) {
      return;
    }

    closeModal();
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  }, [isSubmitSuccessful, reset]);

  console.log("errors");
  console.log(errors);

  return (
    <Dialog open={isOpen} onClose={closeModal} maxWidth={"xs"}>
      <DialogTitle>Add User</DialogTitle>
      <Box component="form" onSubmit={handleSubmit(onSubmitHandler)}>
        <DialogContent dividers>
          <DialogContentText mb={2}>
            Please enter the information for a new user.
          </DialogContentText>
          <Box display={"flex"} flexDirection={"column"} gap={2}>
            <TextField
              fullWidth
              label="First Name"
              autoFocus
              helperText={errors.firstname && errors.firstname.message}
              error={!!errors.firstname}
              size="small"
              inputProps={{ "data-testid": "firstname" }}
              {...register("firstname")}
            />
            <TextField
              fullWidth
              label="Last Name"
              autoFocus
              helperText={errors.lastname && errors.lastname.message}
              error={!!errors.lastname}
              size="small"
              inputProps={{ "data-testid": "lastname" }}
              {...register("lastname")}
            />
            <TextField
              fullWidth
              label="Email"
              autoFocus
              helperText={errors.email && errors.email.message}
              error={!!errors.email}
              size="small"
              inputProps={{ "data-testid": "email" }}
              {...register("email")}
            />
            <TextField
              fullWidth
              label="Password"
              autoFocus
              helperText={errors.password && errors.password.message}
              error={!!errors.email}
              size="small"
              inputProps={{ "data-testid": "password" }}
              {...register("password")}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal}> Cancel </Button>
          <LoadingButton type="submit" loading={false}>
            Add
          </LoadingButton>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default AddUserModal;
