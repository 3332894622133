import { IUser } from "features/user/types";

import { api, Tags } from "./api";

export const userApi = api.injectEndpoints({
  endpoints: build => ({
    loginUser: build.mutation<IUser, { email: string; password: string }>({
      query: payload => ({
        url: "/login",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: [Tags.USERS],
    }),
    getUsers: build.query<{ users: IUser[] }, void>({
      query: () => `/users`,
      providesTags: [Tags.USERS],
    }),
    createUser: build.mutation<
      number,
      {
        email: string;
        password: string;
        firstname: string;
        lastname: string;
        roles: string[];
      }
    >({
      query: payload => ({
        url: "/users",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: [Tags.USERS, Tags.LABUSERS],
    }),
    updateUser: build.mutation<{ user: IUser }, IUser>({
      query: payload => ({
        url: `/users/${payload.id}`,
        method: "PATCH",
        body: payload,
      }),
      invalidatesTags: [Tags.USERS],
    }),
    deleteUser: build.mutation<void, number>({
      query: id => ({
        url: `/users/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [Tags.USERS, Tags.LABUSERS],
    }),
    restoreUser: build.mutation<void, number>({
      query: id => ({
        url: `/users/${id}/restore`,
        method: "POST",
      }),
      invalidatesTags: [Tags.USERS, Tags.LABUSERS],
    }),
  }),
});

export const {
  useLoginUserMutation,
  useGetUsersQuery,
  useCreateUserMutation,
  useUpdateUserMutation,
  useDeleteUserMutation,
  useRestoreUserMutation,
} = userApi;
