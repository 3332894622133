import { useCallback, useMemo, useRef, useState } from "react";
import { useDrop } from "react-dnd";
import { DraggableItemTypes } from "types";
import { v4 as uuidv4 } from "uuid";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {
  Rectangle,
  Color,
} from "@pavonis/pavonis-api/src/api/experiments/slides/images/image_model";
import DraggableRectangle, {
  DraggableRectangleType,
} from "components/DraggableRectangle";
import styles from "./WebCamera.module.css";

interface Props {
  photoUrl: string;
  handleImageDelete: () => void;
  handleImageUpdate: (rectangles: Rectangle[]) => void;
  rectangles?: Rectangle[];
  colors?: Color[];
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ColorBlock = (c?: Color) => {
  <Button variant="contained" size="medium" sx={{ color: "white" }}>
    Accept
  </Button>;
};

const Preview: React.FC<Props> = ({
  photoUrl,
  handleImageDelete,
  handleImageUpdate,
  rectangles,
  colors,
}) => {
  const rectanglesWithId = useMemo(
    () =>
      rectangles?.map(r => ({
        ...r,
        id: uuidv4(),
      })) ?? [],
    [rectangles],
  );

  const imageRef = useRef<HTMLImageElement>(null);

  const [scales, setScales] = useState<{ width: number; height: number }>();
  const [blocks, setBlocks] = useState<DraggableRectangleType[]>([]);

  const normalizeBlocks = (widthScale: number, heightScale: number) => {
    if (!rectanglesWithId) return;

    const newRectangles = rectanglesWithId.map(r => {
      const newX = r.x * widthScale;
      const newY = r.y * heightScale;
      const newWidth = r.width * widthScale;
      const newHeight = r.height * heightScale;

      return { ...r, x: newX, y: newY, width: newWidth, height: newHeight };
    });

    setBlocks(newRectangles);
  };

  const updateBlock = useCallback(
    (id: string, updates: Partial<Rectangle>) => {
      const updatedBlocks = [...blocks];

      const blockIdx = updatedBlocks.findIndex(block => id === block.id);

      if (blockIdx === -1) return;

      updatedBlocks.splice(blockIdx, 1, { ...blocks[blockIdx], ...updates });

      setBlocks(updatedBlocks);
    },
    [blocks],
  );

  const [, drop] = useDrop(
    () => ({
      accept: DraggableItemTypes.RECTANGLE,
      drop: (item: { left: number; top: number; id: string }, monitor) => {
        const delta = monitor.getDifferenceFromInitialOffset();

        if (delta) {
          const left = Math.round(item.left + delta.x);
          const top = Math.round(item.top + delta.y);

          updateBlock(item.id, { x: left, y: top });
        }
      },
    }),
    [updateBlock],
  );

  const onAccept = () => {
    if (!scales) return;

    const { width, height } = scales;

    const newBlocks = blocks.map(r => {
      const newX = r.x / width;
      const newY = r.y / height;
      const newWidth = r.width / width;
      const newHeight = r.height / height;

      return { ...r, x: newX, y: newY, width: newWidth, height: newHeight };
    });

    handleImageUpdate(newBlocks);
  };

  return (
    <>
      <Box
        ref={drop}
        width="fit-content"
        height="fit-content"
        position="absolute">
        <img
          src={photoUrl}
          className={styles.preview}
          ref={imageRef}
          onLoad={() => {
            if (!imageRef.current) return;

            const ws =
              imageRef.current.clientWidth / imageRef.current.naturalWidth;
            const hs =
              imageRef.current.clientHeight / imageRef.current.naturalHeight;

            normalizeBlocks(ws, hs);
            setScales({ width: ws, height: hs });
          }}
        />
        {blocks.map((block, idx) => (
          <DraggableRectangle
            key={idx}
            rectangle={block}
            updateBlock={updateBlock}
          />
        ))}
        {colors &&
          blocks.map((block, idx) => {
            const color = colors[idx];

            return (
              <Box
                key={idx}
                position="absolute"
                left={block.x}
                width="fit-content"
                height="fit-content">
                <Typography variant="h6" color="#FFFFFF">
                  R: {color.red.toFixed(1)}
                </Typography>
                <Typography variant="h6" color="#FFFFFF">
                  G: {color.green.toFixed(1)}
                </Typography>
                <Typography variant="h6" color="#FFFFFF">
                  B: {color.blue.toFixed(1)}
                </Typography>
                <Typography variant="h6" color="#FFFFFF">
                  δC: {color.delta?.toFixed(1)}
                </Typography>
              </Box>
            );
          })}
      </Box>
      <Box position={"absolute"} bottom={"20px"} display="flex" gap={2}>
        <Button
          variant="contained"
          size="medium"
          sx={{ color: "white" }}
          onClick={onAccept}>
          Accept
        </Button>
        <Button
          variant="contained"
          size="medium"
          color="error"
          sx={{ color: "white" }}
          onClick={handleImageDelete}>
          Delete
        </Button>
      </Box>
    </>
  );
};

export default Preview;
