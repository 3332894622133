import cx from "classnames";
import { FC } from "react";
import { FileUploader } from "react-drag-drop-files";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import Button from "@mui/material/Button";
import styles from "./FileUpload.module.css";

const FILE_TYPES = ["JPG", "PNG", "BMP", "JPEG", "WEBP"];

interface ButtonProps {
  btnText?: string;
  disabled?: boolean;
}

const UploadPhotoButton: React.FC<ButtonProps> = ({ disabled, btnText }) => (
  <Button
    variant="outlined"
    size="small"
    color="inherit"
    sx={{ minWidth: "165px" }}
    startIcon={<AddPhotoAlternateIcon />}
    disabled={disabled}>
    {btnText}
  </Button>
);

interface Props extends ButtonProps {
  onFileChange: (file: File) => void;
  fileTypes?: string[];
}

const FileUpload: FC<Props> = ({
  onFileChange,
  fileTypes = FILE_TYPES,
  btnText = "Upload photo",
  disabled = false,
}) =>
  disabled ? (
    <UploadPhotoButton disabled={disabled} btnText={btnText} />
  ) : (
    <FileUploader
      handleChange={onFileChange}
      name="file"
      types={fileTypes}
      hoverTitle="Drop here"
      classes={cx(styles.dragger, { [styles.disabled]: disabled })}
      disabled={disabled}>
      {/* to easier manipulate drop area styles */}
      <div className={cx(styles.placeholder)} />
      <div>
        <UploadPhotoButton disabled={disabled} btnText={btnText} />
      </div>
    </FileUploader>
  );

export default FileUpload;
