import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import { GridToolbarContainer } from '@mui/x-data-grid-pro';

interface Props {
  addBtnText: string;
  handleAdd: () => void;
  children?: React.ReactNode;
}

const Toolbar: React.FC<Props> = ({ addBtnText, handleAdd, children }) => (
  <GridToolbarContainer>
    <Button color="primary" startIcon={<AddIcon />} onClick={handleAdd}>
      {addBtnText}
    </Button>

    {children}
  </GridToolbarContainer>
);

export default Toolbar;
