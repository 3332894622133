/* eslint-disable prettier/prettier */
import { FieldValues, RegisterOptions } from "react-hook-form";
import { Step } from "@pavonis/pavonis-api/src/api/experiments/experiment.model";
import {
  ExperimentStatus,
  Rectangle,
} from "@pavonis/pavonis-api/src/api/experiments/slides/images/image_model";

export enum InputType {
  NUMBER_INPUT = "number",
  FLOAT_INPUT = "float",
  CHECKBOX = "checkbox",
  SELECT = "select",
  TEXT_INPUT = "text",
  TEXT_AREA = "text_area",
}

export interface MetricElement {
  inputType: InputType;
  label: string;
  name: keyof Step | TreatmentInputs;
  registerOptions?: RegisterOptions<FieldValues, string>;
  measurement?: string;
  units?: string[];
  unit?: string;
  options?: { label: string | number; value: string | number }[];
  isDisabled?: boolean;
}

export enum TreatmentInputs {
  PREPARATION = "preparation",
  PRIMARY_ANALYTE = "primaryAnalyte",
  SECONDARY_ANALYTE = "secondaryAnalyte",
  THIRD_ANALYTE = "thirdAnalyte",
}

export type SlideImageDictValue =
  | {
    url?: string;
    id?: number;
    rectangles?: Rectangle[];
  }
  | undefined;

export interface SlideImageDictionary {
  [k: string]: SlideImageDictValue[] | null;
}

export interface ExperimentsImageDictionary {
  [k: string]:
  | {
    [k in ExperimentStatus]: SlideImageDictionary;
  }
  | null;
}
