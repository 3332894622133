import classNames from "classnames";
import { NavLink } from "react-router-dom";
import { ListItemText } from "@mui/material";
import List from "@mui/material/List";
import { useAppSelector } from "app/hooks";
import { SidebarRoutes } from "features/navigation/types";
import { UserRoles } from "features/user/types";
import styles from "./Sidebar.module.css";

const MENU_ITEMS = [{ title: "Experiments", route: SidebarRoutes.Experiments }];
const PROTECTED_MENU_ITEMS = [{ title: "Labs", route: SidebarRoutes.Labs }];

const Sidebar = () => {
  const { user } = useAppSelector(state => state.user);

  return (
    <List
      component="nav"
      aria-label="main mailbox folders"
      disablePadding
      sx={{ minWidth: "240px" }}>
      {MENU_ITEMS.map((item, index) => (
        <NavLink to={item.route} end={!index} key={item.title}>
          {({ isActive }) => (
            <ListItemText
              primary={item.title}
              className={classNames(styles.listItem, {
                [styles.active]: isActive,
              })}
            />
          )}
        </NavLink>
      ))}

      {user?.roles?.includes(UserRoles.ADMIN) &&
        PROTECTED_MENU_ITEMS.map(item => (
          <NavLink to={item.route} key={item.title}>
            {({ isActive }) => (
              <ListItemText
                primary={item.title}
                className={classNames(styles.listItem, {
                  [styles.active]: isActive,
                })}
              />
            )}
          </NavLink>
        ))}
    </List>
  );
};

export default Sidebar;
