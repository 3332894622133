import { z } from 'zod';

export const UserSchema = z.object({
  id: z.number().optional(),
  email: z.string().email(),
  firstname: z.string().default(''),
  lastname: z.string().default(''),
  roles: z.string().array().default([]),
  password: z.string().min(7),
  token: z.string().optional(),
  refreshToken: z.string().optional(),
  lab_id: z.number().optional(),
  archived: z.boolean().optional(),
});

type User = z.infer<typeof UserSchema>;

export default User;
