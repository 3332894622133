import { Outlet } from "react-router-dom";
import Box from "@mui/material/Box";

import Navbar from "components/Navbar";

const LoggedInLayout = () => (
  <Box
    height={"100vh"}
    bgcolor={"Background.default"}
    display="flex"
    flexDirection={"column"}>
    <Navbar />
    <Box display={"flex"} height="100%">
      <Outlet />
    </Box>
  </Box>
);

export default LoggedInLayout;
