import { useMemo, useState, useCallback } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import GroupIcon from "@mui/icons-material/Group";
import { Tooltip } from "@mui/material";
import {
  GridRowId,
  GridColumns,
  DataGrid,
  GridActionsCellItem,
} from "@mui/x-data-grid";
//import { DataGridPro } from "@mui/x-data-grid-pro";
import Lab from "@pavonis/pavonis-api/src/api/labs/lab.model";
import Loader from "@pavonis/pavonis-hub/src/components/Loader";
import PageTemplate from "@pavonis/pavonis-hub/src/components/PageTemplate";
import Toolbar from "@pavonis/pavonis-hub/src/components/Toolbar";
import { BaseRoutes } from "features/navigation/types";
import { useGetLabsQuery } from "services/labs";
import AddLabsModal from "../components/AddLabModal";

const Labs: React.FC = () => {
  const { data: labs, isLoading } = useGetLabsQuery();

  const [isAddModalOpen, setIsAddModalOpen] = useState(false);

  const rows: Lab[] = useMemo(() => labs ?? [], [labs]);

  const navigate = useNavigate();

  const handleNavigation = useCallback(
    (labId: string, id: GridRowId) => {
      navigate(generatePath(BaseRoutes.Users, { id: String(labId) }), {
        state: { id },
      });
    },
    [navigate],
  );

  const columns: GridColumns = useMemo(
    () => [
      {
        field: "name",
        headerName: "Name",
        width: 120,
      },
      {
        field: "address",
        headerName: "Address",
        sortable: false,
        flex: 1,
      },
      {
        field: "actions",
        type: "actions",
        headerName: "Actions",
        width: 150,
        getActions: ({ id, row }) => {
          return [
            <GridActionsCellItem
              key={id}
              icon={
                <Tooltip title={"Users"}>
                  <GroupIcon />
                </Tooltip>
              }
              label="Users"
              color="inherit"
              onClick={() => handleNavigation(row.id, id)}
            />,
          ];
        },
      },
    ],
    [],
  );

  return (
    <PageTemplate title="Labs">
      {isLoading ? (
        <Loader />
      ) : (
        <DataGrid
          rows={rows}
          columns={columns}
          sx={{ height: "calc(100% - 34px - 1rem)" }}
          components={{
            Toolbar,
          }}
          componentsProps={{
            toolbar: {
              addBtnText: "Add New",
              handleAdd: () => {
                setIsAddModalOpen(true);
              },
            },
          }}
        />
      )}

      <AddLabsModal
        isOpen={isAddModalOpen}
        closeModal={() => {
          setIsAddModalOpen(false);
        }}
      />
    </PageTemplate>
  );
};

export default Labs;
