import { useDispatch } from "react-redux";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import { useAppSelector } from "app/hooks";
import { logout } from "features/user/userSlice";

const Navbar = () => {
  const dispatch = useDispatch();

  const { user } = useAppSelector(state => state.user);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar sx={{ p: 2 }}>
          <img src="/assets/pavonis-logo.png" alt="Pavonis Logo" />
          <Typography
            variant="h4"
            textAlign={"center"}
            sx={{ flexGrow: 2, alignSelf: "center", paddingInline: 3 }}>
            Pavonis Lab
          </Typography>
          <Box display="flex" alignItems="center" gap={2}>
            <div>Hello, {user?.email}</div>
            <Button color="inherit" onClick={() => dispatch(logout())}>
              Log out
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Navbar;
