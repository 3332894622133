import { Navigate, Route, Routes } from "react-router-dom";
import Experiment from "features/experiments/pages/Experiment";
import Experiments from "features/experiments/pages/Experiments";
import Login from "features/user/pages/Login";
import Labs from "features/usermanagement/pages/Labs";
import LabUsers from "features/usermanagement/pages/LabUsers";
import Dashboard from "./components/Dashboard";
import LoggedInLayout from "./components/LoggedInLayout";
import ProtectedRoute from "./components/ProtectedRoute";
import { BaseRoutes, SidebarRoutes } from "./types";

const RouterContainer = () => (
  <Routes>
    <Route
      path={BaseRoutes.Base}
      element={<ProtectedRoute component={LoggedInLayout} />}>
      <Route element={<Navigate to={SidebarRoutes.Experiments} />} index />
      <Route path={BaseRoutes.Experiment} element={<Experiment />} />

      <Route element={<Dashboard />}>
        <Route path={SidebarRoutes.Experiments} element={<Experiments />} />
        <Route path={SidebarRoutes.Labs} element={<Labs />} />
        <Route path={BaseRoutes.Users} element={<LabUsers />} />
      </Route>
    </Route>

    <Route path={BaseRoutes.Login} element={<Login />} />
    <Route
      path={BaseRoutes.NotFound}
      element={<ProtectedRoute component={() => <h1>Page not found</h1>} />}
    />
  </Routes>
);

export default RouterContainer;
