import { InputType, MetricElement, TreatmentInputs } from "../types";

export const TREATMENTS: MetricElement[] = [
  {
    inputType: InputType.CHECKBOX,
    label: "Slide Treatment",
    name: TreatmentInputs.PREPARATION,
    measurement: "Blank",
  },
  {
    inputType: InputType.CHECKBOX,
    label: "Primary Analyte",
    name: TreatmentInputs.PRIMARY_ANALYTE,
    measurement: "None",
  },
  {
    inputType: InputType.CHECKBOX,
    label: "Secondary Analyte",
    name: TreatmentInputs.SECONDARY_ANALYTE,
    measurement: "None",
    isDisabled: true,
  },
];

export const STEPS: MetricElement[] = [
  {
    inputType: InputType.TEXT_INPUT,
    label: "Protein Name",
    name: "name",
    registerOptions: { required: true },
  },
  {
    inputType: InputType.TEXT_INPUT,
    label: "Protein Accession",
    name: "accession",
    registerOptions: { required: false },
  },
  {
    inputType: InputType.FLOAT_INPUT,
    label: "Exposure Time",
    name: "exposureTime",
    units: ["seconds", "minutes", "hours"],
    measurement: "minutes",
    registerOptions: { required: false, valueAsNumber: true },
  },
  {
    inputType: InputType.FLOAT_INPUT,
    label: "Concentration",
    name: "concentration",
    measurement: "mg/mL",
    units: ["mg/mL", "ug/mL", "ng/mL", "nM", "uM", "mM"],
    registerOptions: { required: false, valueAsNumber: true },
  },
  {
    inputType: InputType.TEXT_INPUT,
    label: "Buffers",
    name: "buffer",
    registerOptions: { required: false },
  },
  {
    inputType: InputType.FLOAT_INPUT,
    label: "Temperature",
    name: "temperature",
    registerOptions: { valueAsNumber: true },
  },
  {
    inputType: InputType.FLOAT_INPUT,
    label: "pH",
    name: "pH",
    registerOptions: { valueAsNumber: true },
  },
];

export enum WatchFields {
  TYPE = "type",
  SPOTS_COUNT = "spotsCount",
  DESCRIPTION = "description",
}

export const ANALYTE_STEPS: MetricElement[] = [
  {
    inputType: InputType.SELECT,
    label: "Type",
    name: WatchFields.TYPE,
    options: [
      {
        label: "Purified Protein",
        value: "Purified Protein",
      },
      { label: "Blood", value: "Blood" },
      { label: "Blood Serum", value: "Blood Serum" },
      { label: "Urine", value: "Urine" },
      { label: "Saliva", value: "Saliva" },
      { label: "Plasma", value: "Plasma" },
    ],
  },
  {
    inputType: InputType.FLOAT_INPUT,
    label: "Exposure Time",
    name: "exposureTime",
    measurement: "seconds",
    registerOptions: { valueAsNumber: true },
  },
  {
    inputType: InputType.FLOAT_INPUT,
    label: "Temperature",
    name: "temperature",
    registerOptions: { valueAsNumber: true },
  },
  {
    inputType: InputType.FLOAT_INPUT,
    label: "pH",
    name: "pH",
    registerOptions: { valueAsNumber: true },
  },
];

export const AD_HOC_STEP: MetricElement[] = [
  {
    inputType: InputType.TEXT_INPUT,
    label: "Substance",
    name: "accession",
    registerOptions: { required: false },
  },
  {
    inputType: InputType.FLOAT_INPUT,
    label: "Exposure Time",
    name: "exposureTime",
    units: ["seconds", "minutes", "hours"],
    measurement: "seconds",
  },
  {
    inputType: InputType.FLOAT_INPUT,
    label: "Concentration",
    name: "concentration",
    units: ["mg/mL", "ug/mL", "ng/mL", "nM", "uM", "mM"],
    measurement: "mg/mL",
  },
  {
    inputType: InputType.FLOAT_INPUT,
    label: "Temperature",
    name: "temperature",
    registerOptions: { valueAsNumber: true },
  },
  {
    inputType: InputType.TEXT_AREA,
    label: "Notes",
    name: "notes",
  },
];

export const DEFAULT_NUM_OF_SPOTS = 8;
