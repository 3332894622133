import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Button, Typography } from '@mui/material';

interface Props {
  title?: string;
  hasBackBtn?: boolean;
  onBackBtnClick?: () => void;
  children: React.ReactNode;
}

const PageTemplate: React.FC<Props> = ({
  title,
  hasBackBtn = false,
  onBackBtnClick,
  children,
}) => (
  <Box width={'100%'} height="100%" p={2}>
    <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
      {hasBackBtn && (
        <Button
          color="inherit"
          startIcon={<ArrowBackIcon />}
          onClick={onBackBtnClick}>
          Back
        </Button>
      )}
      {!!title && (
        <Typography
          variant="h4"
          sx={{ flex: '1 0 auto', mb: 0, p: 0, textAlign: 'center' }}>
          {title}
        </Typography>
      )}
    </Box>
    {children}
  </Box>
);

export default PageTemplate;
