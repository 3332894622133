import { ConfirmProvider } from "material-ui-confirm";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { BrowserRouter } from "react-router-dom";
import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import { LicenseInfo } from "@mui/x-data-grid-pro";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import RouterContainer from "features/navigation/routes";

const THEME = createTheme({
  palette: {
    mode: "dark",
  },
});

LicenseInfo.setLicenseKey(
  "a033f110696fe4cd97124a3d3a22b3e5Tz01MjQ1MCxFPTE2OTczMTUzNDQ1NTIsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=",
);

function App() {
  return (
    <ThemeProvider theme={THEME}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <CssBaseline />
        <ConfirmProvider>
          <DndProvider backend={HTML5Backend}>
            <BrowserRouter>
              <RouterContainer />
            </BrowserRouter>
          </DndProvider>
        </ConfirmProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;
