import { Outlet } from "react-router-dom";
import Box from "@mui/material/Box";
import Sidebar from "components/Sidebar";

const Dashboard = () => (
  <>
    <Box>
      <Sidebar />
    </Box>
    <Outlet />
  </>
);

export default Dashboard;
