/* eslint-disable prettier/prettier */
import { useCallback, useState } from "react";

import {
  Controller,
  FieldValues,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import { useNavigate } from "react-router-dom";
import CheckIcon from "@mui/icons-material/Check";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import {
  Button,
  FormControl,
  //FormControlLabel,
  Grid,
  //Radio,
  //RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import Experiment from "@pavonis/pavonis-api/src/api/experiments/experiment.model";
import { ExperimentStatus } from "@pavonis/pavonis-api/src/api/experiments/slides/images/image_model";
import {
  DEFAULT_NUM_OF_SPOTS,
  WatchFields,
} from "features/experiments/constants";
import { SidebarRoutes } from "features/navigation/types";
import { useUpdateExperimentMutation } from "services/experiments";
import Slide from "../Slide";
import SpotWrapper from "./components/SpotWrapper";

interface Props {
  experiment: Experiment;
}

const NewExperiment: React.FC<Props> = ({ experiment }) => {
  const { register, control, watch, handleSubmit } = useForm({
    defaultValues: {
      slidesCount: experiment?.slide_count ?? 5,
      [WatchFields.DESCRIPTION]: experiment?.description ?? "",
      [WatchFields.SPOTS_COUNT]:
        experiment?.slide_circle_count ?? DEFAULT_NUM_OF_SPOTS,
      slideThickness: 200,
    },
  });
  const [spotsCount, description] = watch([
    WatchFields.SPOTS_COUNT,
    WatchFields.DESCRIPTION,
  ]);
  const navigate = useNavigate();

  const [updateExperiment, { isLoading }] = useUpdateExperimentMutation();

  const [activeSpots, setActiveSpots] = useState([0]);
  const [isEditMode, setIsEditMode] = useState(
    !experiment?.protocol?.steps?.[0],
  );

  const onSubmit = async (values?: any) => {
    if (!experiment) return;

    if (isEditMode) {
      const steps = [...(experiment.protocol?.steps ?? [])];

      // steps consist of spot treatments
      activeSpots.forEach(spotIdx => {
        steps[spotIdx] = values;
      });

      const updatedExperiment = {
        ...experiment,
        protocol: { ...experiment.protocol, steps },
      };

      if (!experiment?.id) return;
      await updateExperiment({
        id: experiment.id,
        experiment: updatedExperiment,
      });
    }

    setIsEditMode(prev => !prev);
  };

  const onSpotClick = (spots: number[] | number) => {
    if (!Array.isArray(spots)) return;

    setActiveSpots(spots);

    // edit mode is based on first spot if multiple spots are active
    setIsEditMode(!experiment?.protocol?.steps?.[spots[0]]);
  };

  const handleDoneDefinition: SubmitHandler<FieldValues> = async values => {
    if (!experiment?.id) return;

    await updateExperiment({
      id: experiment.id,
      experiment: {
        ...experiment,
        description: values.description,
        slide_circle_count: values.spotsCount,
        slide_count: +values.slidesCount,
        status: ExperimentStatus.DEFINED,
      },
    });

    navigate(SidebarRoutes.Experiments);
  };

  const onDescriptionBlur = async () => {
    if (!experiment?.id) return;

    await updateExperiment({
      id: experiment.id,
      experiment: {
        ...experiment,
        description,
      },
    });
  };

  const onSpotsCountChange = async (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    onChange: (v: number) => void,
  ) => {
    const v = +e.target.value;

    onChange(v);

    if (!experiment?.id) return;
    await updateExperiment({
      id: experiment.id,
      experiment: {
        ...experiment,
        slide_circle_count: v,
      },
    });

    const spotsInRange = activeSpots.filter(i => i + 1 <= v);

    if (spotsInRange.length) {
      setActiveSpots(spotsInRange);

      return;
    }

    setActiveSpots([0]);
  };

  const renderSpotTitle = useCallback(
    (index: number) => {
      const steps = experiment.protocol?.steps;
      const step = steps?.[index];

      if (step?.preparation[0]?.isBlank) return "B";
      if (step) return <CheckIcon />;

      return <QuestionMarkIcon />;
    },
    [experiment],
  );

  return (
    <>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems="center"
        mt={3}
        component="form"
        onSubmit={handleSubmit(handleDoneDefinition)}>
        <Typography variant="h5" flexShrink={0} gutterBottom>
          Define Experiment
        </Typography>

        <Button
          variant="contained"
          size="medium"
          disabled={
            !description ||
            (experiment?.protocol?.steps?.length ?? 0) !==
            (spotsCount ?? DEFAULT_NUM_OF_SPOTS)
          }
          onClick={handleDoneDefinition}
          type="submit">
          Done Definition
        </Button>
      </Box>
      <Grid
        mt={"-10px"}
        xs={12}
        container
        columnSpacing={3}
        rowSpacing={2}
        alignItems="baseline">
        <Grid xs={4} item md={4} display="flex" alignItems="center" gap={3}>
          <Typography flexShrink={0}>Number of Slides</Typography>
          <TextField
            size="small"
            type="number"
            inputProps={{ min: 1 }}
            defaultValue={5}
            {...register("slidesCount")}
          />
        </Grid>
        <Grid item md={4} display="flex" alignItems="center" gap={3}>
          <Typography flexShrink={0}>Thickness</Typography>
          <TextField
            size="small"
            type="number"
            inputProps={{ min: 1 }}
            defaultValue={5}
            {...register("slideThickness")}
          />
          </Grid>
          <Grid xs={4} item md={4} display="flex" alignItems="center" gap={3}>
            <FormControl
              sx={{ flexDirection: "row", gap: 3, alignItems: "center", mt: 2 }}>
              <Typography flexShrink={0}>Number of Spots</Typography>
              <Controller
                name={WatchFields.SPOTS_COUNT}
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange } }) => (
                  <TextField
                    size="small"
                    type="number"
                    inputProps={{ min: 6, max: 16 }}
                    defaultValue={8}
                    onChange={event => onSpotsCountChange(event, onChange)}
                  />
                )}
              />
            </FormControl>
          </Grid>
        <Grid item xs={12} display="flex" alignItems="center" gap={3}>
          <Typography flexShrink={0}>Experiment Description</Typography>
          <TextField
            fullWidth
            size="small"
            {...register(WatchFields.DESCRIPTION)}
            onBlur={onDescriptionBlur}
          />
        </Grid>
        <Grid
          item
          xs={12}
          display="flex"
          alignItems="center"
          justifyContent={"center"}
          gap={3}>
          <Slide
            activeSpots={activeSpots}
            numOfSpots={spotsCount}
            onClick={onSpotClick}
            renderSpotTitle={renderSpotTitle}
          />
        </Grid>
        <Grid item xs={12} display="flex" alignItems="center" gap={3}>
          <SpotWrapper
            activeSpots={activeSpots}
            numOfSpots={spotsCount}
            uploadData={onSubmit}
            isEditMode={isEditMode}
            experiment={experiment}
            isUpdating={isLoading}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default NewExperiment;
