import { useEffect, useMemo, useRef } from "react";
import { useDrag } from "react-dnd";
import { DraggableItemTypes } from "types";
import Box from "@mui/material/Box";
import { Rectangle } from "@pavonis/pavonis-api/src/api/experiments/slides/images/image_model";

interface Props {
  rectangle: DraggableRectangleType;
  updateBlock: (id: string, updates: Partial<Rectangle>) => void;
}

export interface DraggableRectangleType extends Rectangle {
  id: string;
}

const DraggableRectangle: React.FC<Props> = ({ rectangle, updateBlock }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: DraggableItemTypes.RECTANGLE,
      item: {
        id: rectangle.id,
        left: rectangle.x,
        top: rectangle.y,
      },
      collect: monitor => ({
        isDragging: !!monitor.isDragging(),
      }),
    }),
    [rectangle, updateBlock],
  );

  const resizeObserver = useMemo(
    () =>
      new window.ResizeObserver(entries => {
        window.requestAnimationFrame(() => {
          if (!Array.isArray(entries) || !entries.length) {
            return;
          }

          for (const entry of entries) {
            const { inlineSize, blockSize } = entry.borderBoxSize[0];

            const width = Math.round(inlineSize);
            const height = Math.round(blockSize);

            if (rectangle?.height === height && rectangle.width === width)
              return;

            height &&
              updateBlock(rectangle.id, {
                width,
                height,
              });
          }
        });
      }),
    [rectangle, updateBlock],
  );

  drag(ref);

  useEffect(() => {
    const target = ref.current;

    if (!target) return;
    resizeObserver.observe(target);

    return () =>
      target ? resizeObserver.unobserve(target) : resizeObserver.disconnect();
  }, [resizeObserver]);

  if (isDragging) {
    return <div ref={ref} />;
  }

  return (
    <Box
      ref={ref}
      position="absolute"
      top={`${rectangle.y}px`}
      left={`${rectangle.x}px`}
      width={`${rectangle.width}px`}
      height={`${rectangle.height}px`}
      border="1px solid yellow"
      overflow={"auto"}
      sx={{
        opacity: +!isDragging,
        cursor: "move",
        resize: "both",
      }}
    />
  );
};

export default DraggableRectangle;
