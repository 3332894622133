import { isNil } from "lodash";
import { useCallback } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Experiment, {
  Step,
  Treatment,
} from "@pavonis/pavonis-api/src/api/experiments/experiment.model";
import Loader from "@pavonis/pavonis-hub/src/components/Loader";
import BorderedBox from "components/BorderedBox";
import Slide from "features/experiments/components/Slide";
import { getUniqueSteps } from "features/experiments/helpers/getUniqueSteps";

interface Props {
  experiment: Experiment;
  treatmentType: keyof Treatment;
  isPrimary: boolean;
}

const Protocol: React.FC<Props> = ({
  experiment,
  treatmentType,
  isPrimary,
}) => {
  const steps = experiment.protocol?.steps;

  const { uniqueSteps, spotTitles } = getUniqueSteps(
    steps ?? [],
    treatmentType,
    isPrimary,
  );
  const hasBlankStep = uniqueSteps.find(step => !isNil(step) && step.isBlank);

  const renderSpotTitle = useCallback(
    (index: number) => {
      const step = steps?.[index];

      if (step?.[treatmentType][+!isPrimary]?.isBlank) return "B";

      return spotTitles[index];
    },
    [steps, isPrimary, spotTitles, treatmentType],
  );

  if (!steps) return <Loader />;

  const renderValues = (step: Step) =>
    Object.entries(step)
      .filter(i => !isNil(i[1]) && `${i[1]}`.length)
      .map(([key, value]) => `${key}: ${value}`)
      .join(", ");

  return (
    <>
      <Slide renderSpotTitle={renderSpotTitle} />
      <BorderedBox title="Protocol">
        {uniqueSteps.map((step, stepId) => {
          if (isNil(step)) return;
          const { isBlank } = step;

          return (
            <Box
              display="flex"
              gap={3}
              alignItems="center"
              key={`step-${stepId}`}>
              <Typography variant="h3">
                {isBlank ? "B" : stepId + +!hasBlankStep}
              </Typography>
              <Box>
                {isBlank ? (
                  <Typography>Blank control circle: No preparation</Typography>
                ) : (
                  <>{renderValues(step)}</>
                )}
              </Box>
            </Box>
          );
        })}
      </BorderedBox>
    </>
  );
};

export default Protocol;
