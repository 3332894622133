import {
  Control,
  Controller,
  FieldErrors,
  FieldValues,
  UseFormRegister,
} from "react-hook-form";
import { Checkbox, MenuItem, Select, TextField } from "@mui/material";

import CustomTextarea from "components/CustomTextarea";
import { InputType, MetricElement } from "../types";

export const renderMetricElements = (
  metricElement: MetricElement,
  register: UseFormRegister<FieldValues | any>,
  errors: FieldErrors<FieldValues>,
  control: Control<FieldValues | any>,
) => {
  switch (metricElement.inputType) {
    case InputType.NUMBER_INPUT: {
      return (
        <TextField
          size="small"
          {...register(metricElement.name, metricElement.registerOptions)}
          disabled={metricElement.isDisabled}
          type="number"
          inputProps={{ min: 0 }}
          sx={{ maxWidth: 80 }}
          error={!!errors[metricElement.name]}
        />
      );
    }
    case InputType.FLOAT_INPUT: {
      return (
        <TextField
          size="small"
          {...register(metricElement.name, metricElement.registerOptions)}
          disabled={metricElement.isDisabled}
          type="number"
          inputProps={{ step: 0.1 }}
          sx={{ maxWidth: 80 }}
          error={!!errors[metricElement.name]}
        />
      );
    }
    case InputType.CHECKBOX: {
      return (
        <Controller
          control={control}
          name={metricElement.name}
          rules={metricElement.registerOptions}
          render={({ field: { onChange, value } }) => (
            <Checkbox
              checked={!!value}
              onChange={onChange}
              disabled={metricElement.isDisabled}
              size="small"
            />
          )}
        />
      );
    }
    case InputType.SELECT: {
      return (
        <Controller
          control={control}
          name={metricElement.name}
          defaultValue={metricElement?.options?.[0].value}
          rules={metricElement.registerOptions}
          render={({ field: { onChange, value } }) => (
            <Select
              value={value}
              onChange={onChange}
              disabled={metricElement.isDisabled}
              size="small"
              error={!!errors[metricElement.name]}
              fullWidth>
              {metricElement.options?.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          )}
        />
      );
    }
    case InputType.TEXT_AREA: {
      return (
        <Controller
          control={control}
          name={metricElement.name}
          defaultValue={metricElement?.options?.[0].value}
          rules={metricElement.registerOptions}
          render={({ field: { onChange, value } }) => (
            <CustomTextarea
              value={value}
              onChange={onChange}
              disabled={metricElement.isDisabled}
              error={!!errors[metricElement.name]}
            />
          )}
        />
      );
    }
    default: {
      return (
        <TextField
          {...register(metricElement.name, metricElement.registerOptions)}
          disabled={metricElement.isDisabled}
          size="small"
          fullWidth
          error={!!errors[metricElement.name]}
          autoComplete="off"
        />
      );
    }
  }
};
