export interface IUser {
  firstName: string;
  lastName: string;
  email: string;
  id: number;
  roles: UserRoles[];
  token: string;
  lab_id?: number;
}

export enum UserRoles {
  ADMIN = "admin",
  RESEARCHER = "researcher",
  CLINICIAN = "clinician",
}
