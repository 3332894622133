/*eslint dot-notation: 0*/
import Image from "mui-image";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { object, string, TypeOf } from "zod";

import { zodResolver } from "@hookform/resolvers/zod";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Box,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";

import { BaseRoutes } from "features/navigation/types";
import { setUser } from "features/user/userSlice";
import { isErrorWithMessage } from "services/helpers";
import { useLoginUserMutation } from "services/user";

const loginSchema = object({
  email: string().min(1, "User ID is required").email("Email is invalid"),
  password: string().min(1, "Password is required"),
});

type LoginInput = TypeOf<typeof loginSchema>;

const Login: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    register,
    formState: { errors, isSubmitSuccessful },
    reset,
    handleSubmit,
  } = useForm<LoginInput>({
    resolver: zodResolver(loginSchema),
  });

  const [loginUser, { isLoading, error }] = useLoginUserMutation();

  const [showPassword, setShowPassword] = useState(false);

  const onSubmitHandler: SubmitHandler<LoginInput> = async values => {
    const currUser = await loginUser(values).unwrap();

    if (currUser) {
      dispatch(setUser(currUser));

      navigate(BaseRoutes.Base);
    }
  };

  const shouldRenderErrorMessage = error && isErrorWithMessage(error);

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  }, [isSubmitSuccessful, reset]);

  return (
    <Box
      bgcolor={"Background.default"}
      height={"100vh"}
      display="flex"
      flexDirection={"column"}>
      <Box
        className="container"
        display={"flex"}
        height="100%"
        flexDirection={"column"}
        alignItems="center"
        p={5}
        component="form"
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit(onSubmitHandler)}>
        <Image
          shift="top"
          src="/assets/pavonis-logo-1270x373.png"
          width="100%"
          height="30%"
          fit="scale-down"
          duration={3000}
        />
        <Typography variant="h2" marginY={5}>
          Sign In
        </Typography>
        <TextField
          id="userid"
          label="User ID"
          placeholder="User ID"
          required
          error={!!errors["email"]}
          helperText={errors["email"] ? errors["email"].message : ""}
          sx={{ width: "25ch" }}
          {...register("email")}
          inputProps={{ "data-testid": "email" }}
        />
        <FormControl sx={{ m: 3, width: "25ch" }}>
          <InputLabel htmlFor="password" error={!!errors["password"]}>
            Password
          </InputLabel>
          <OutlinedInput
            id="password"
            inputProps={{ "data-testid": "password" }}
            type={showPassword ? "text" : "password"}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibilitiy"
                  onClick={() => {
                    setShowPassword(!showPassword);
                  }}
                  onMouseDown={e => e.preventDefault()}
                  edge="end">
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="Password"
            required
            error={!!errors["password"]}
            aria-describedby="password-error"
            {...register("password")}
          />
          {!!errors["password"] && (
            <FormHelperText error id="password-error">
              {errors["password"].message}
            </FormHelperText>
          )}
          {shouldRenderErrorMessage && (
            <Typography
              variant="overline"
              display="block"
              gutterBottom
              sx={{
                mt: "0.75rem",
                mb: 0,
                fontSize: "0.6rem",
                lineHeight: "0.75rem",
                color: "#f44336",
              }}>
              {error.data.error}
            </Typography>
          )}
        </FormControl>
        <LoadingButton variant="contained" type="submit" loading={isLoading}>
          Sign In
        </LoadingButton>
      </Box>
    </Box>
  );
};

export default Login;
