/* eslint-disable @typescript-eslint/no-unused-vars */
import { isEmpty, isNil } from "lodash";
import React from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Box,
  Collapse,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Experiment, {
  EXPERIMENT_TYPES,
} from "@pavonis/pavonis-api/src/api/experiments/experiment.model";
import SlideImage, {
  ExperimentStatus,
} from "@pavonis/pavonis-api/src/api/experiments/slides/images/image_model";
import { BASE_URL } from "services/api";
import AggregateSection from "../AggregateSection";
import SlideSection from "../SlideSection";

interface Props {
  experiment: Experiment;
}

const ReportGrid: React.FC<Props> = ({ experiment }) => {
  const allImages: SlideImage[] | undefined = experiment.slides?.flatMap(s => {
    return s.images || [];
  });

  return experiment.kind === EXPERIMENT_TYPES.AD_HOC
    ? AdHocReport(experiment, allImages)
    : StructuredExperimentReport(experiment, allImages);
};

const StructuredExperimentReport = (
  experiment: Experiment,
  allImages: SlideImage[] | undefined,
) => {
  const preparationImages =
    allImages?.filter(i => {
      return i.protocol_stage == ExperimentStatus.PREPARED;
    }) || [];
  const experimentImages1 = allImages?.filter(i => {
    return i.protocol_stage == ExperimentStatus.BEGUN && i.step_index == 1;
  });
  const experimentImages2 =
    allImages?.filter(i => {
      return i.protocol_stage == ExperimentStatus.BEGUN && i.step_index == 2;
    }) || [];

  return (
    <Box>
      {ProtocolStageSection("Slide Preparation", experiment, preparationImages)}
      {experimentImages1
        ? ProtocolStageSection("First Analyte", experiment, experimentImages1)
        : null}
      {!isEmpty(experimentImages2)
        ? ProtocolStageSection("Second Analyte", experiment, experimentImages2)
        : null}
    </Box>
  );
};

const AdHocReport = (
  experiment: Experiment,
  allImages: SlideImage[] | undefined,
) => {
  const steps = experiment.protocol?.steps?.filter(s => s?.preparation);
  const images = steps?.map((step, idx) => {
    return allImages?.filter(i => {
      return (
        i.protocol_stage == ExperimentStatus.AD_HOC && i.step_index == idx + 1
      );
    });
  });
  const stepCount = steps?.length ?? 0;
  const imageCount = images?.length;

  return (
    <Box>
      {steps?.map((_, i) => {
        const imgs = images?.[i];

        return imgs ? (
          <>
            <Typography marginTop={5} variant="h5">
              {`Step ${i + 1}`}
            </Typography>
            <TableContainer component={Paper}>
              <Table
                sx={{ minWidth: 650, maxWidth: 1080 }}
                aria-label="Preparation Color Values">
                <TableBody>{SlideSection(experiment, imgs, 0)}</TableBody>
              </Table>
            </TableContainer>
          </>
        ) : (
          <Typography marginTop={5} variant="h5">
            {"No images for this step"}
          </Typography>
        );
      })}
      ;
    </Box>
  );
};

const ProtocolStageSection = (
  title: string,
  experiment: Experiment,
  images: SlideImage[],
) => {
  return (
    <>
      <Typography marginTop={5} variant="h5">
        {title}{" "}
      </Typography>
      <TableContainer component={Paper}>
        <Table
          sx={{ minWidth: 650, maxWidth: 1080 }}
          aria-label="Preparation Color Values">
          <TableBody>
            {experiment.slides?.map((s, idx) =>
              SlideSection(experiment, images, idx),
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default ReportGrid;
