import download from "downloadjs";
import moment from "moment";
import Experiment from "@pavonis/pavonis-api/src/api/experiments/experiment.model";
import SlideImage, {
  ExperimentStatus,
  Rectangle,
} from "@pavonis/pavonis-api/src/api/experiments/slides/images/image_model";
import { store, RootState } from "app/store";

import { api, Tags, BASE_URL } from "./api";

export const downloadCSV = async (experiment: Experiment) => {
  const token = (store.getState() as RootState).user.user?.token;
  const url = `${BASE_URL}/api/v1/experiments/${experiment.id}/csv`;
  const name =
    moment(experiment.created_date).format("YYYYMMDD") +
    "-" +
    experiment.id +
    ".csv";

  const res = await fetch(url, {
    headers: {
      Authorization: `Bearer:${token}`,
    },
  });
  const blob = await res.blob();

  await download(blob, name, "text/csv");
};

export const experimentsApi = api.injectEndpoints({
  endpoints: build => ({
    getExperiments: build.query<Experiment[], void>({
      query: () => `/experiments`,
      providesTags: [Tags.EXPERIMENT],
    }),
    createExperiment: build.mutation<{ experiment: Experiment }, Experiment>({
      query: payload => ({
        url: "/experiments",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: [Tags.EXPERIMENT],
    }),
    getExperiment: build.query<Experiment, number>({
      query: id => `/experiments/${id}`,
      providesTags: [Tags.EXPERIMENT],
    }),
    updateExperiment: build.mutation<
      Experiment,
      { id: number; experiment: Experiment }
    >({
      query: ({ id, experiment }) => ({
        url: `/experiments/${id}`,
        method: "PATCH",
        body: experiment,
      }),
      invalidatesTags: [Tags.EXPERIMENT],
    }),
    getSlideImages: build.query<
      { value: SlideImage[] },
      {
        id: number;
        slideId: string;
      }
    >({
      query: payload =>
        `/experiments/${payload.id}/slides/${payload.slideId}/images`,
      providesTags: [Tags.SLIDE_IMAGE],
    }),
    createSlideImage: build.mutation<
      { value: SlideImage },
      {
        id: number;
        slideId: string;
        protocolStage: ExperimentStatus;
        stepIndex: number;
        file: FormData;
      }
    >({
      query: ({ id, slideId, file, protocolStage, stepIndex }) => {
        file.append("protocol_stage", protocolStage);
        file.append("step_index", stepIndex.toString());

        return {
          url: `/experiments/${id}/slides/${slideId}/images`,
          method: "POST",
          body: file,
        };
      },
      invalidatesTags: [Tags.EXPERIMENT],
    }),
    updateSlideImageRectangles: build.mutation<
      { rectangles: Rectangle[] },
      {
        id: number;
        imageId: number;
        slideId: string;
        rectangles: Rectangle[];
      }
    >({
      query: payload => ({
        url: `/experiments/${payload.id}/slides/${payload.slideId}/images/${payload.imageId}/rectangles`,
        method: "POST",
        body: payload.rectangles,
      }),
      invalidatesTags: [Tags.EXPERIMENT],
    }),
    archiveSlideImage: build.mutation<
      void,
      {
        id: number;
        slideId: string;
        imageid: number;
      }
    >({
      query: ({ id, slideId, imageid }) => ({
        url: `/experiments/${id}/slides/${slideId}/images/${imageid}`,
        method: "DELETE",
      }),
      invalidatesTags: [Tags.EXPERIMENT],
    }),
    archiveExperiment: build.mutation<void, { id: number }>({
      query: ({ id }) => ({
        url: `/experiments/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [Tags.EXPERIMENT],
    }),
    getExperimentCSV: build.query<void, { id: number }>({
      query: ({ id }) => ({
        url: `/experiments/${id}/csv`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useGetExperimentsQuery,
  useCreateExperimentMutation,
  useGetExperimentQuery,
  useGetExperimentCSVQuery,
  useUpdateExperimentMutation,
  useCreateSlideImageMutation,
  useUpdateSlideImageRectanglesMutation,
  useArchiveSlideImageMutation,
  useArchiveExperimentMutation,
} = experimentsApi;
