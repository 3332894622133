import { z } from 'zod';

export const LabSchema = z.object({
  id: z.number().optional(),
  name: z.string().min(1),
  address: z.string().optional(),
});

type Lab = z.infer<typeof LabSchema>;

export default Lab;
