import { z } from 'zod';

import { numericString } from '../../util';

import { ExperimentStatus } from './slides/images/image_model';
import Slide from './slides/slide.model';

export enum TIME_UNITS {
  SECONDS = 'sec',
  MINUTES = 'min',
  HOURS = 'hours',
}

export enum CONCENTRATION_UNITS {
  MG_ML = 'mg/mL',
  UG_ML = 'ug/mL',
}

export enum EXPERIMENT_TYPES {
  STRUCTURED = 'structured',
  AD_HOC = 'adhoc',
}

// TODO: Step is misnamed here, find a better name and refactor
export const Step = z.object({
  accession: z.string().optional(),
  formula: z.string().optional(),
  url: z.string().url().optional(),
  name: z.string().optional(),
  manufacturerId: z.string().optional(),
  type: z.string().optional(),
  pH: numericString(z.number().optional()),
  buffer: z.string().optional(),
  concentration: numericString(z.number().optional()),
  concentrationUnits: z.string().optional(),
  pauseBefore: z.number().optional(),
  exposureTime: numericString(z.number().optional()),
  exposureTimeUnits: z.string().optional(),
  temperature: z.number().optional(),
  circleNumber: z.number().int().optional(),
  isBlank: z.boolean().optional(),
  notes: z.string().optional(),
});

export type Step = z.infer<typeof Step>;

const Treatment = z.object({
  preparation: z.array(Step.nullable()),
  analytes: z.array(Step.nullable()),
});

export type Treatment = z.infer<typeof Treatment>;

const Steps = z.array(Treatment.nullable());

export type Steps = z.infer<typeof Steps>;

const Protocol = z.object({
  preparation_cure_time: z.number().optional(),
  preparation_cure_temp: z.number().optional(),
  steps: Steps,
});

const Experiment = z.object({
  id: z.number().int().optional(),
  description: z.string(),
  scientist: z.number().int(),
  lab: z.number().int().optional(),
  slide_count: numericString(z.number().default(1)),
  replicates_experiment: z.number().int().optional(),
  slide_circle_count: numericString(z.number().min(8).max(16)),
  protocol: Protocol.nullable().optional(),
  status: z.nativeEnum(ExperimentStatus),
  slides: Slide.array().optional().nullable(),
  notes: z.string().optional().nullable(),
  created_date: z.string().datetime().optional(),
  kind: z.string().default(EXPERIMENT_TYPES.AD_HOC),
});

type Experiment = z.infer<typeof Experiment>;

export default Experiment;
