import { ExperimentStatus } from "@pavonis/pavonis-api/src/api/experiments/slides/images/image_model";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { ExperimentsImageDictionary, SlideImageDictValue } from "./types";

const initialState: ExperimentsImageDictionary = {};

export const slideImagesSlice = createSlice({
  name: "slideImages",
  initialState,
  reducers: {
    createImage: (
      state,
      action: PayloadAction<{
        experimentId: number;
        slideId: string;
        stage: ExperimentStatus;
        image: SlideImageDictValue;
        stepIndex: number;
      }>,
    ) => {
      const { experimentId, slideId, stage, image, stepIndex } = action.payload;
      const stepIndexAsArray = stepIndex - 1;
      const slide = state[experimentId]?.[stage]?.[slideId];
      const newSlideImages: SlideImageDictValue[] = [...(slide ?? [])];

      newSlideImages[stepIndexAsArray] = image;

      return {
        ...state,
        [experimentId]: {
          ...state[experimentId],
          [stage]: {
            ...state[experimentId]?.[stage],
            [slideId]: newSlideImages,
          },
        },
      };
    },
    updateImage: (
      state,
      action: PayloadAction<{
        experimentId: number;
        slideId: string;
        stage: ExperimentStatus;
        stepIndex: number;
        updates: Partial<SlideImageDictValue>;
      }>,
    ) => {
      const { experimentId, slideId, stage, stepIndex, updates } =
        action.payload;

      const stepIndexAsArray = stepIndex - 1;
      const slide = state[experimentId]?.[stage]?.[slideId];
      const newSlideImages: SlideImageDictValue[] = [...(slide ?? [])];

      newSlideImages[stepIndexAsArray] = {
        ...slide?.[stepIndexAsArray],
        ...updates,
      };

      return {
        ...state,
        [experimentId]: {
          ...state[experimentId],
          [stage]: {
            ...state[experimentId]?.[stage],
            [slideId]: newSlideImages,
          },
        },
      };
    },
    deleteImage: (
      state,
      action: PayloadAction<{
        experimentId: number;
        slideId: string;
        stepIndex: number;
        stage: ExperimentStatus;
      }>,
    ) => {
      const { experimentId, slideId, stage, stepIndex } = action.payload;

      const stepIndexAsArray = stepIndex - 1;
      const slide = state[experimentId]?.[stage]?.[slideId];
      const newSlideImages: SlideImageDictValue[] = [...(slide ?? [])];

      newSlideImages[stepIndexAsArray] = undefined;

      return {
        ...state,
        [experimentId]: {
          ...state[experimentId],
          [stage]: {
            ...state[experimentId]?.[stage],
            [slideId]: newSlideImages,
          },
        },
      };
    },
  },
});

export default slideImagesSlice.reducer;

export const { actions } = slideImagesSlice;
export const { createImage, updateImage, deleteImage } = actions;
