import { Box, CircularProgress, CircularProgressProps } from '@mui/material';

const Loader = ({ ...props }: CircularProgressProps) => (
  <Box
    sx={{
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}>
    <CircularProgress color="inherit" {...props} data-testid="spinner" />
  </Box>
);

export default Loader;
