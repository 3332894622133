import { isEqual } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Box, Button } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";

import Experiment from "@pavonis/pavonis-api/src/api/experiments/experiment.model";
import {
  ExperimentStatus,
  Rectangle,
} from "@pavonis/pavonis-api/src/api/experiments/slides/images/image_model";
import Loader from "@pavonis/pavonis-hub/src/components/Loader";
import { RootState } from "app/store";
import {
  createImage,
  deleteImage,
  updateImage,
} from "features/experiments/slideImagesSlice";
import { SlideImageDictionary } from "features/experiments/types";
import { SidebarRoutes } from "features/navigation/types";
import { BASE_URL } from "services/api";
import {
  useArchiveSlideImageMutation,
  useCreateSlideImageMutation,
  useUpdateExperimentMutation,
  useUpdateSlideImageRectanglesMutation,
} from "services/experiments";
import { toFormData } from "utils/toFormData";
import Photos from "../Photos";
import Protocol from "../Protocol";

interface Props {
  experiment: Experiment;
}

const DefinedExperiment: React.FC<Props> = ({ experiment }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const slideImages = useSelector((state: RootState) => state.slideImages);
  const experimentSlideImages = slideImages[experiment.id ?? 0];
  const postpreparationImages = useMemo(
    () => experimentSlideImages?.[ExperimentStatus.PREPARED] ?? {},
    [experimentSlideImages],
  );

  const [updateExperiment] = useUpdateExperimentMutation();
  const [createSlideImage] = useCreateSlideImageMutation();
  const [updateSlideImage] = useUpdateSlideImageRectanglesMutation();
  const [archiveSlideImage] = useArchiveSlideImageMutation();

  const [isImageLoading, setIsImageLoading] = useState(false);

  const handleSlideImageCreate = useCallback(
    async (
      newPhoto: string,
      slideId: string,
      protocolStage: ExperimentStatus,
    ) => {
      if (!experiment.id) return;

      setIsImageLoading(true);

      const formData = await toFormData(newPhoto);

      const { value } = await createSlideImage({
        id: experiment.id!,
        slideId,
        protocolStage,
        file: formData,
        // always 1 for preparation stage
        stepIndex: 1,
      }).unwrap();

      if (!value || !value.image_location) return;

      const slideImage = {
        url: `${BASE_URL}${value.image_location}`,
        rectangles: value.color_rectangles ?? [],
        id: value.id,
      };

      dispatch(
        createImage({
          experimentId: experiment.id,
          slideId,
          stage: protocolStage,
          image: slideImage,
          stepIndex: 1,
        }),
      );
      setIsImageLoading(false);
    },
    [createSlideImage, experiment.id, dispatch],
  );

  const onImageCapture = useCallback(
    async (newPhoto: string, slideId: string) => {
      await handleSlideImageCreate(
        newPhoto,
        slideId,
        ExperimentStatus.PREPARED,
      );
    },
    [handleSlideImageCreate],
  );

  const handleSlideImageUpdate = useCallback(
    async (
      slidesImages: SlideImageDictionary,
      slideId: string,
      stage: ExperimentStatus,
      updatedRectangles: Rectangle[],
    ) => {
      if (!experiment.id) return;

      const slideImage = slidesImages[slideId];

      if (!slideImage || !slideImage?.[0]?.id) return;

      if (isEqual(slideImage[0].rectangles, updatedRectangles)) return;

      setIsImageLoading(true);

      await updateSlideImage({
        id: experiment.id,
        slideId,
        imageId: slideImage[0].id,
        rectangles: updatedRectangles,
      }).unwrap();

      dispatch(
        updateImage({
          experimentId: experiment.id,
          slideId,
          stage,
          stepIndex: 1,
          updates: {
            rectangles: updatedRectangles,
          },
        }),
      );
      setIsImageLoading(false);
    },
    [experiment.id, updateSlideImage, dispatch],
  );

  const onImageUpdate = useCallback(
    async (slideId: string, updatedRectangles: Rectangle[]) => {
      handleSlideImageUpdate(
        postpreparationImages,
        slideId,
        ExperimentStatus.PREPARED,
        updatedRectangles,
      );
    },
    [postpreparationImages, handleSlideImageUpdate],
  );

  const handleSlideImageDelete = useCallback(
    async (slideId: string, stage: ExperimentStatus) => {
      if (!experiment.id) return;
      setIsImageLoading(true);
      const imagedict = experimentSlideImages?.[stage];
      const image = imagedict?.[slideId]?.slice(-1)?.[0];

      if (image && image.id) {
        await archiveSlideImage({
          id: experiment.id,
          slideId,
          imageid: image.id,
        });
      }

      dispatch(
        deleteImage({
          experimentId: experiment.id,
          slideId,
          stepIndex: 1,
          stage,
        }),
      );

      setIsImageLoading(true);
    },
    [dispatch, experiment.id, archiveSlideImage],
  );

  const onImageDelete = useCallback(
    async (slideId: string) => {
      await handleSlideImageDelete(slideId, ExperimentStatus.PREPARED);
    },
    [handleSlideImageDelete],
  );

  const handleDonePreparation = async () => {
    if (!experiment.id) return;

    await updateExperiment({
      id: experiment.id,
      experiment: {
        ...experiment,
        status: ExperimentStatus.PREPARED,
      },
    });

    navigate(SidebarRoutes.Experiments);
  };

  useEffect(() => {
    if (!experiment.slides) return;

    experiment.slides.forEach(slide => {
      if (!slide.images) return;

      slide.images.forEach(image => {
        if (!slide.id || !experiment.id) return;

        const slideImage = {
          url: `${BASE_URL}${image.image_location}`,
          rectangles: image.color_rectangles ?? [],
          id: image.id,
        };

        dispatch(
          createImage({
            experimentId: experiment.id,
            slideId: slide.id,
            stage: image.protocol_stage,
            image: slideImage,
            stepIndex: 1,
          }),
        );
      });
    });
  }, [experiment.slides, experiment.id, dispatch]);

  return (
    <Box py={3} display="flex" flexDirection={"column"} gap={3}>
      <Box display={"flex"} flexDirection="column" alignItems="center" gap={3}>
        <Protocol
          experiment={experiment}
          treatmentType={"preparation"}
          isPrimary={true}
        />
        <Photos
          title="Post-preparation Photos"
          slides={experiment.slides || []}
          images={postpreparationImages}
          onImageCapture={onImageCapture}
          onImageDelete={onImageDelete}
          onImageUpdate={onImageUpdate}
        />
      </Box>

      <Button
        variant="contained"
        size="medium"
        type="submit"
        sx={{
          alignSelf: "baseline",
          width: "fit-content",
          marginLeft: "auto",
        }}
        onClick={handleDonePreparation}>
        Done Preparation
      </Button>
      <Backdrop open={isImageLoading}>
        <Loader />
      </Backdrop>
    </Box>
  );
};

export default DefinedExperiment;
