import { z } from 'zod';

import SlideImage from './images/image_model';

const Slide = z.object({
  id: z.string().uuid().optional(),
  createdDate: z.string().datetime().optional().nullable(),
  qrCode: z.string().optional().nullable(),
  manufactureDate: z.string().datetime().optional().nullable(),
  madeBy: z.number().optional().nullable(),
  initialThickness: z.number().optional().nullable(),
  preparedThickness: z.number().optional().nullable(),
  finalThickness: z.number().optional().nullable(),
  archived: z.boolean().optional().nullable(),
  circleCount: z.number().optional().nullable(),
  experimentId: z.number().optional().nullable(),
  orderNumber: z.number().optional().nullable(),
  preparationDate: z.string().datetime().optional().nullable(),
  experimentDate: z.string().datetime().optional().nullable(),
  images: SlideImage.array().optional().nullable(),
});

type Slide = z.infer<typeof Slide>;
export default Slide;
