import { Fragment, useState } from "react";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import FlipCameraIosIcon from "@mui/icons-material/FlipCameraIos";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Rectangle } from "@pavonis/pavonis-api/src/api/experiments/slides/images/image_model";
import Slide from "@pavonis/pavonis-api/src/api/experiments/slides/slide.model";
import FileUpload from "components/FileUpload";
import { SlideImageDictionary } from "features/experiments/types";
import WebCamera from "../WebCamera";

interface Props {
  title: string;
  slides: Slide[];
  images: SlideImageDictionary;
  stepIndex?: 1 | 2;
  onImageCapture: (
    newPhoto: string,
    slideId: string,
    images?: Record<string, string>,
  ) => Promise<void>;
  onImageDelete: (slideId: string) => void;
  onImageUpdate: (slideId: string, rectangles: Rectangle[]) => void;
}

const Photos: React.FC<Props> = ({
  title,
  slides,
  images,
  stepIndex = 1,
  onImageCapture,
  onImageDelete,
  onImageUpdate,
}) => {
  const stepIndexAsArray = stepIndex - 1;
  const [isCameraModalOpen, setIsCameraModalOpen] = useState(false);
  const [activeSlideId, setActiveSlideId] = useState<string>(
    slides[0].id ?? "",
  );

  const handleFileUpload = (file: File, slideId?: string) => {
    const reader = new FileReader();

    reader.addEventListener("load", () => {
      if (typeof reader?.result !== "string") return;

      handleImageCapture(reader.result, slideId);
    });

    reader.readAsDataURL(file);
  };

  const handleImageCapture = async (photo: string, slideId?: string) => {
    // using state only to connect modal and slide photo
    const id = slideId ?? activeSlideId;

    if (!id) return;
    if (slideId) {
      setActiveSlideId(slideId);
    }

    await onImageCapture(photo, id);
    setIsCameraModalOpen(true);
  };

  const handleImageDelete = () => {
    onImageDelete(activeSlideId);
  };

  const handleImageUpdate = (rectangles: Rectangle[]) => {
    onImageUpdate(activeSlideId, rectangles);
    setIsCameraModalOpen(false);
  };

  const onCameraIconClick = (slideId?: string) => {
    if (!slideId) return;

    setIsCameraModalOpen(true);
    setActiveSlideId(slideId);
  };

  return (
    <Box flex={1}>
      <Typography variant="h5" flexShrink={0} gutterBottom>
        {title}
      </Typography>
      <Grid container spacing={1} mt={2}>
        {slides.map((slide, index) => {
          const image = images[slide.id ?? 0];

          return (
            <Fragment key={index}>
              <Grid
                item
                xs={6}
                display="flex"
                flexWrap="wrap"
                alignItems={"flex-start"}
                gap={2}>
                <Typography>Slide {index + 1}</Typography>
                {image?.[stepIndexAsArray]?.url?.length ? (
                  <>
                    <LoadingButton
                      variant="outlined"
                      size="small"
                      color="inherit"
                      sx={{ minWidth: "165px" }}
                      startIcon={<InsertPhotoIcon />}
                      onClick={() => onCameraIconClick(slide.id)}>
                      Preview
                    </LoadingButton>
                    <LoadingButton
                      variant="outlined"
                      size="small"
                      color="inherit"
                      sx={{ minWidth: "165px" }}
                      startIcon={<FlipCameraIosIcon />}
                      onClick={() => {
                        handleImageDelete();
                      }}>
                      Remove photo
                    </LoadingButton>
                  </>
                ) : (
                  <>
                    <LoadingButton
                      variant="outlined"
                      size="small"
                      color="inherit"
                      sx={{ minWidth: "165px" }}
                      startIcon={<CameraAltIcon />}
                      onClick={() => onCameraIconClick(slide.id)}>
                      Take photo
                    </LoadingButton>
                    <FileUpload
                      onFileChange={(file: File) => {
                        handleFileUpload(file, slide.id);
                      }}
                    />
                  </>
                )}
              </Grid>
            </Fragment>
          );
        })}
      </Grid>
      <WebCamera
        isOpen={isCameraModalOpen}
        handleClose={() => setIsCameraModalOpen(false)}
        handleImageCapture={handleImageCapture}
        handleImageDelete={handleImageDelete}
        handleImageUpdate={handleImageUpdate}
        photo={images[activeSlideId]?.[stepIndexAsArray]?.url}
        rectangles={images[activeSlideId]?.[stepIndexAsArray]?.rectangles}
      />
    </Box>
  );
};

export default Photos;
