import Lab from "@pavonis/pavonis-api/src/api/labs/lab.model";
import User from "@pavonis/pavonis-api/src/api/users/user.model";

import { api, Tags } from "./api";

export const labsApi = api.injectEndpoints({
  endpoints: build => ({
    getLabs: build.query<Lab[], void>({
      query: () => `/labs`,
      providesTags: [Tags.LABS],
    }),
    getLabUsers: build.query<User[], number>({
      query: id => `/labs/${id}/users`,
      providesTags: [Tags.LABUSERS],
    }),
    createLab: build.mutation<{ lab: Lab }, Lab>({
      query: payload => ({
        url: "/labs",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: [Tags.LABS],
    }),
  }),
});

export const { useGetLabsQuery, useGetLabUsersQuery, useCreateLabMutation } =
  labsApi;
