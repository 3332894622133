import { useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import Box from "@mui/material/Box";

import Lab, { LabSchema } from "@pavonis/pavonis-api/src/api/labs/lab.model";
import { useCreateLabMutation } from "services/labs";

interface Props {
  isOpen: boolean;
  closeModal: () => void;
}

const AddLabsModal = ({ isOpen, closeModal }: Props) => {
  const [createLab] = useCreateLabMutation();

  const {
    register,
    formState: { errors, isSubmitSuccessful },
    reset,
    handleSubmit,
  } = useForm<Lab>({
    resolver: zodResolver(LabSchema),
  });

  const onSubmitHandler: SubmitHandler<Lab> = async values => {
    const res = await createLab(values);

    if ("error" in res) {
      return;
    }

    closeModal();
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  }, [isSubmitSuccessful, reset]);

  return (
    <Dialog open={isOpen} onClose={closeModal} maxWidth={"xs"}>
      <DialogTitle>Add Laboratory</DialogTitle>
      <Box component="form" onSubmit={handleSubmit(onSubmitHandler)}>
        <DialogContent dividers>
          <DialogContentText mb={2}>
            Please enter the information for a new laboratory.
          </DialogContentText>
          <Box display={"flex"} flexDirection={"column"} gap={2}>
            <TextField
              fullWidth
              label="Name"
              autoFocus
              helperText={errors.name && errors.name.message}
              error={!!errors.name}
              size="small"
              inputProps={{ "data-testid": "name" }}
              {...register("name")}
            />
            <TextField
              fullWidth
              label="Address"
              autoFocus
              helperText={errors.address && errors.address.message}
              error={!!errors.address}
              size="small"
              inputProps={{ "data-testid": "address" }}
              rows={3}
              multiline
              {...register("address")}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal}> Cancel </Button>
          <LoadingButton type="submit" loading={false}>
            Add
          </LoadingButton>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default AddLabsModal;
